@media screen and (min-width: 768px) {
    .mega-nav--level-1 {
        background-color: var(--color-dark);
        color: var(--color-text-muted);
        z-index: 10;
        position: absolute;
        top: calc(2rem/16);
        left: 35%;
        right: 0;
        height: 100%;
        visibility: hidden;
        opacity: 0;
        padding-top: calc(95rem/16);
        max-height: calc(100vh - calc(150rem/16));
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
        }
    }
    .mega-nav__list--level-1 {
        max-width: calc(900rem/16);
        width: 100%;

        &.has-two-columns {
            column-count: 2;
        }
    }
    .show-overlay .mega-nav__item.is-open > .mega-nav--level-1,
    .show-overlay .mega-nav__item.is-open--only-desktop > .mega-nav--level-1 {
        opacity: 1;
        visibility: visible;
    }

    .mega-nav__title--level-1 {
        width: 100%;
        display: flex;
        flex: 0 0 100%;
        padding: 1rem 0;
        font-family: var(--font-default-bold);
        font-weight: var(--font-default-bold-weight);
    }
    .mega-nav__title-link--level-1,
    .mega-nav__item-content--level-1 {
        display: block;
        padding:0;
    }
    .mega-nav__item--level-1 {
        display: block;
        padding: .5rem 0;
        break-inside: avoid-column;
    }
    .mega-nav__title-link--level-1.is-open,
    .mega-nav__item-content--level-1.is-open{
        color: var(--color-primary);
    }
    .mega-nav--level-1__go-back {
        display: none;
    }
}
@media screen and (min-width: 768px) and (max-width:1399px){
    .mega-nav--level-1 {
        padding-top: calc(35rem/16);
    }
}
