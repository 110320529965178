@media (hover: none) {
    .mega-nav__item.has-children > .simple-nav__item-content--link {
        display: none;
    }
    .mega-nav__item.has-no-children > .simple-nav__item-content--toggle {
        display: none;
    }
}

.mega-nav__item-content--toggle {
    display: none;
}
.mega-nav__container {
    padding-left: 14%;
    width: 85%;
    @media screen and (max-width: 1699px)  and (min-width: 768px){
        padding-left: 10%;
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        padding-left: calc(15rem/16);
        padding-right: calc(15rem/16);
        width: 100%;
    }
}
.mega-nav__item-content--link, .mega-nav__title-link {
    font-size: clamp(calc(16rem/16), 1.3vw, calc(20rem/16));
    letter-spacing: calc(1rem/16);
    background-color: transparent;
    border: none;
    color:#fff;
    font-family: var(--font-decorative);
    box-shadow: none;
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}
.mega-nav__item-content-icon {
    font-size: calc(25rem/16);
    width: calc(45rem/16);
    margin-right: calc(5rem/16);
}
.mega-nav__content-wrapper {
    padding-right: clamp(calc(10rem/16), 6vw, calc(100rem/16));
    display: grid;
    grid-template-columns: 1fr clamp(calc(200rem/16), 24vw, calc(460rem/16));
    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
}