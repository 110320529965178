.result-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: calc(15rem/16) auto;
    width: 100%;

    @media screen and (min-width: 768px) {
        margin: calc(25rem/16) auto;
    }
}