:root {
    --color-primary: #CC0429; /* changed*/
    --color-primary-gradient:  linear-gradient(224.43deg, #882436 0%, #CC044D 30.62%, #CC0422 100%); /* changed*/
    --color-secondary: #6c757d;
    --color-text-default: #F2F1EF; /* changed*/
    //--color-text-dark-default: ;
    --color-text-muted: #738091; /* changed*/

    --color-default: #2D343E;
    --color-white: #FFF;
    --color-light:#F2F1EF; /* changed*/
    --color-light-grey: #F8F9FA;
    --color-dark-grey: #BEC2C6;
    --color-grey:#B8B8B8;  /* changed*/
    --color-dark: #030c17;  /* changed*/
    --color-dark-blue: #0E1B2D; /* changed*/
    --color-blue-muted:#121D2B;
    --color-middle-blue: #464E59; /* changed*/
    --color-blue: #081322; /* changed*/

    --color-success: #28A745;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #235275;
    --color-secondary-dark: #424D56;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #f5254c;
    --color-secondary-light: #6E8090;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "Montserrat", sans-serif;
    --font-default-ultra-bold: "Montserrat Bold", sans-serif;
    --font-default-bold: "Montserrat Medium", sans-serif;
    --font-decorative: "Bebas Neue", sans-serif;

    --nav-height: calc(150rem/16);
    --nav-height--mobile: calc(60rem/16);

    --font-size-default: 1rem;
    --font-size-default-xs: calc(12rem/16);

    --grid-gutter: calc(60rem/16);
    --container-max-width: calc(1552rem/16);
    --container-md-max-width: calc(994rem/16);
    --container-lg-max-width: calc(1258rem/16);
    --container-padding: calc(30rem/16);

    --cookie-toggle-switch-width: calc(48rem/16);
    --cookie-toggle-switch-height: calc(26rem/16);
    --cookie-toggle-switch-toggle-size: calc(22rem/16);

}