.waypoint-nav {
    position: absolute;
    padding-top: calc(90rem / 16);
    top: 0;
    height: 100%;
    pointer-events: none;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        padding-top: calc(300rem / 16);
    }

    &:before {
        content: '';
        position: absolute;
        width: calc(2rem / 16);
        height: 100%;
        top: calc(90rem / 16);
        background-color: #5F6976;
        left: calc(30rem / 16);

        @media screen and (min-width: 768px) {
            top: calc(300rem / 16);
        }

        @media screen and (min-width: 1200px) {
            left: calc(70rem / 16);
        }
    }
}

.waypoint-nav-placeholder {
    pointer-events: auto;
    max-width: calc(300rem / 16);
    @media screen and (min-width: 768px) {
        max-width: calc(200rem / 16);
    }
    @media screen and (min-width: 1200px) {
        max-width: calc(400rem / 16);
    }
}

.waypoint-nav__default-title {
    font-size: clamp(calc(50rem/16), 14vw, calc(270rem/16));
    letter-spacing: clamp(calc(5rem/16), 0.5vw, calc(15rem / 16));
    font-family: var(--font-decorative);
    line-height: 0.8;
    position: relative;
    padding-left: calc(70rem / 16);
    margin-bottom: calc(30rem / 16);

    @media screen and (min-width: 1200px) {
        padding-left: calc(110rem / 16);
        margin-bottom: calc(70rem / 16);
    }

    &:before {
        content: '';
        position: absolute;
        width: calc(10rem / 16);
        height: 100%;
        top: 0;
        background: var(--color-primary-gradient);
        left: calc(30rem / 16);

        @media screen and (min-width: 1200px) {
            left: calc(70rem / 16);
        }

    }
}

.waypoint-nav__link {
    font-size: clamp(calc(20rem / 16), 1.75vw, calc(32rem / 16));
    letter-spacing: calc(2.5rem / 16);
    font-family: var(--font-decorative);
    padding: calc(10rem/16) 0;
    display: grid;
    line-height: 1;
    grid-template-columns: calc(30rem / 16) 1fr;
    grid-template-rows: auto auto;
    gap: calc(5rem / 16) calc(10rem / 16);
    grid-template-areas:
    "waypoint-nav__count waypoint-nav__title"
    ". waypoint-nav__sub-title";
    @include hyphenation(6, 3, 3);

    @media screen and (min-width: 768px) {
        padding: calc(20rem/16) 0;
    }

    @media screen and (min-width: 1200px) {
        grid-template-columns: calc(60rem / 16) 1fr;
        gap: calc(5rem / 16) calc(37rem / 16);
    }

}

.waypoint-nav__count {
    grid-area: waypoint-nav__count;
}

.waypoint-nav__title {
    will-change: opacity, transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    grid-area: waypoint-nav__title;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.waypoint-nav__line {
    left: calc(30rem / 16);
    position: absolute;
    top: 0;
    background: linear-gradient(224.43deg, #882436 0%, #CC044D 30.62%, #CC0422 100%);
    height: calc(25rem/16);
    width: calc(3rem/16);
    margin-top: calc(9rem/16);
    transform: translateY(-100%);
    opacity:0;
    will-change: opacity, transform;
    transition: transform 0.4s cubic-bezier(1,.01,0,1.22), opacity 0.2s ease;
    @media screen and (min-width: 768px) {
        margin-top: calc(21rem/16);
        height: calc(50rem/16);
        width: calc(4rem/16);
        left: calc(30rem / 16);
    }
    @media screen and (min-width: 1200px) {
        margin-top: calc(24rem/16);
        height: calc(95rem/16);
        width: calc(4rem/16);
        left: calc(70rem / 16);
    }
}

.waypoint-nav__link:nth-of-type(1).active ~ .waypoint-nav__line {
    opacity:1;
    transform: translateY(0px);
}
.waypoint-nav__link:nth-of-type(2).active ~ .waypoint-nav__line {
    opacity:1;
    transform: translateY(57px);
    @media screen and (min-width: 768px) {
        transform: translateY(90px);
    }
    @media screen and (min-width: 1200px) {
        transform: translateY(135px);
    }
}
.waypoint-nav__link:nth-of-type(3).active ~ .waypoint-nav__line {
    opacity:1;
    transform: translateY(116px);
    @media screen and (min-width: 768px) {
        transform: translateY(176px);
    }
    @media screen and (min-width: 1200px) {
        transform: translateY(250px);
    }
}
.waypoint-nav__sub-title {
    grid-area: waypoint-nav__sub-title;
    color: var(--color-grey);
    font-size: clamp(calc(10rem / 16), 1vw, calc(22rem / 16));
    font-family: var(--font-default);
    line-height: calc(29 / 24);
    letter-spacing: 0;
    text-shadow: 0 0 calc(10rem / 16) rgba(0, 0, 0, 0.7);
    will-change: opacity, transform, color;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
}
.active .waypoint-nav__sub-title {
    color: #fff;
}
.waypoint-nav__link:nth-of-type(2) .waypoint-nav__title,
.waypoint-nav__link:nth-of-type(2) .waypoint-nav__sub-title,{
    transition-delay: 0.05s;
}
.waypoint-nav__link:nth-of-type(1) .waypoint-nav__title,
.waypoint-nav__link:nth-of-type(1) .waypoint-nav__sub-title,{
    transition-delay: 0.1s;
}
@media screen and (max-width: 767px) {
    .waypoint-nav--is-active .waypoint-nav__title, .waypoint-nav--is-active .waypoint-nav__sub-title {
        opacity:0;
        transform: translateX(40%);
    }
}