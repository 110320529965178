.barrierfree-modal {
    background-color: #fff;
    padding: calc(20rem/16);
    color: var(--color-blue);
    @media screen and(min-width: 768px){
        padding: calc(36rem/16);
    }
}
.barrierfree-modal__content {
    font-size: calc(12rem/16);
}
.barrierfree-modal__icon{
    font-size: calc(40rem/16);
    color: #cc044d;
    margin-right: calc(12rem/16);
}
.barrierfree-modal__title{
    font-family: var(--font-decorative);
    font-size: calc(20rem/16);
    padding-right: calc(20rem/16);
    @media screen and(min-width: 768px){
        font-size: calc(26rem/16);
    }
}

.barrierfree-modal__content .wysiwyg ul li:before {
    width: unset;
    height: unset;
    background-color: transparent;
    content:var(--icon-check);
    font-family: iconfont;
    color: var(--color-primary);
    top: 0;
}
.barrierfree-modal__content .wysiwyg ul li{
    padding-bottom: calc(8rem/16);
}