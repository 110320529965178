.main-navbar-toggle {
    background-color: transparent;
    height: 100%;
    box-shadow: none;
    border: none;
    padding: 0;
    display: flex;
}
.main-navbar-toggle__text {
    padding: calc(20rem/16) calc(28rem/16);
    font-size: calc(30rem/16);
    font-family: var(--font-decorative);
    letter-spacing: calc(1rem/16);
    height: 100%;
    display: flex;
    align-items: center;
    line-height:1;
    color: var(--color-text-default);
    border-left: calc(1rem/16) solid var(--color-middle-blue);

    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.main-navbar-toggle__button {
    background: var(--color-primary-gradient);
    height: 100%;
    width: calc(60rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 768px) {
        width: calc(114rem/16);
    }
}

.main-navbar-toggle__line-wrapper {
    position: relative;
    height: calc(20rem/16);
    width: calc(30rem/16);
    @media screen and (min-width: 768px) {
        height: calc(31rem/16);
        width: calc(44rem/16);
    }
}

.main-navbar-toggle__line {
    position: absolute;
    width: calc(30rem/16);
    height: calc(1rem/16);
    left: 0;
    transition-timing-function: ease;
    transition-duration: .15s;
    transform-origin: center;
    transition-property: transform;
    background-color: #fff;

    @media screen and (min-width: 768px) {
        width: calc(44rem/16);
        height: calc(2rem/16);
    }

    &:nth-of-type(1) {
        top: 0px;
    }

    &:nth-of-type(2) {
        top: calc(8rem/16);
        @media screen and (min-width: 768px) {
            top: calc(14rem/16);
        }
    }

    &:nth-of-type(3) {
        top: calc(16rem/16);
        @media screen and (min-width: 768px) {
            top: calc(28rem/16);
        }
    }
}

.main-navbar-toggle.is-open .main-navbar-toggle__line{
    &:nth-of-type(1) {
        animation: line1 forwards 0.3s ease;
    }
    &:nth-of-type(2) {
        animation: line2 forwards 0.3s ease;
    }
    &:nth-of-type(3) {
        animation: line3 forwards 0.3s ease;
    }
}

@keyframes line1 {
    0% {
        transform: translateY(0); }
    30% {
        transform: translateY(14px);
    }
    100% {
        transform: translateY(14px) rotate(45deg);
    }
}
@media screen and (max-width: 767px) {
    @keyframes line1 {
        0% {
            transform: translateY(0); }
        30% {
            transform: translateY(8px);
        }
        100% {
            transform: translateY(8px) rotate(45deg);
        }
    }
}

@keyframes line2 {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@keyframes line3 {
    0% {
        transform: translateY(0); }
    30% {
        transform: translateY(-14px);
    }
    100% {
        transform: translateY(-14px) rotate(-45deg);
    }
}
@media screen and (max-width: 767px) {
    @keyframes line3 {
        0% {
            transform: translateY(0); }
        30% {
            transform: translateY(-8px);
        }
        100% {
            transform: translateY(-8px) rotate(-45deg);
        }
    }
}