.pagination-wrapper{
    display: flex;
    align-items: center;
    text-align: right;
}
.pagination-wrapper--bottom{
    justify-content: flex-end;
    margin-top: calc(16rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}
.pagination{
    display: inline-flex;
    margin: 0 1rem;
}

.page-item{
    position: relative;
}
.page-item.disabled .page-link {
    background-color: transparent;
}
.page-link{
    position: relative;
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    border: 0;
    color: var(--color-light);
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    padding: .375rem;
    border-radius: 0;
}

.page-item.active:not(.page-item--arrow) .page-link:before,
.page-item:not(.page-item--arrow) .page-link:hover:before{
    background: transparent;
}

.page-item.active .page-link,
.page-item .page-link:hover{
    border: 0;
    color: var(--color-light);
    background: transparent;
}

.page-item+.page-item{
    margin-left: calc(4rem/16);
}

.page-item--arrow .page-link:hover{
    background-color: transparent;
}
.page-item--arrow {
    margin: 0;
}
.page-link__prev,
.page-link__next {
    margin: -.125rem 0 0;
    font-size: calc(10rem/16)!important;
    display: block;
}