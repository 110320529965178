table {
    color: var(--color-light)!important;
    table-layout: auto!important;
    width: 100%;
}


table>thead th{
    font-size: calc(16rem/16);
    letter-spacing: calc(0.5rem/16);
    font-family: var(--font-default-bold);
    vertical-align: middle;
}

table>tbody td {
    vertical-align: middle;
    font-size: calc(20rem/16);
    position: relative;
    @media screen and (min-width: 768px) {
        font-size: calc(24rem/16);
    }
}
table>tbody th {
    vertical-align: middle;
}

table>tbody td ul li p {
    font-size: calc(12rem/16);
    margin-bottom: 0;
    @media screen and (min-width: 768px) {
        font-size: 1rem;
    }
}

.wysiwyg table{
    width: 100%;
    border-color: transparent;
    float: none;
}

.wysiwyg table>thead{
    background-color: var(--color-primary-light);
}

.wysiwyg table>thead th{
    font-size: calc(14rem/16);
    letter-spacing: calc(1rem/16);
    font-family: var(--font-decorative);
    text-transform: uppercase;
    background-color: var(--color-primary-light);
    color: #fff;
    vertical-align: top;
    @media screen and (min-width: 768px) {
        letter-spacing: calc(2rem/16);
        font-size: calc(18rem/16);
    }
}

.wysiwyg table>tbody th{
    font-size: calc(14rem/16);
    letter-spacing: calc(1rem/16);
    font-family: var(--font-decorative);
    text-transform: uppercase;
    color: #fff;
    vertical-align: top;
    @media screen and (min-width: 768px) {
        letter-spacing: calc(2rem/16);
        font-size: calc(18rem/16);
    }
}

.wysiwyg table td {
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}

.wysiwyg table th,
.wysiwyg table td{
    padding: calc(7rem/16) calc(6rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(17rem/16) calc(20rem/16);
    }
}

.wysiwyg table>tbody>tr{
    border-bottom: 1px solid #dedede;
}

.wysiwyg thead p{
    margin-bottom: calc(6rem/16);
}

.table-responsive::-webkit-scrollbar {
    height: calc(12rem / 16);
}
.table-responsive::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px grey;
}
.table-responsive::-webkit-scrollbar-thumb {
    background: var(--color-primary-gradient);
}
/* Facts Table */

.facts-table__check,
.facts-table__cross{
    height: calc(25rem/16);
    width: calc(25rem/16);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.facts-table__check {
    background-color: var(--color-light);
}
.facts-table__cross:after,
.facts-table__check:after {
    font-size: calc(10rem/16);
    font-family: iconfont;
    color: var(--color-primary);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.facts-table__check:after {
    content: var(--icon-check);
}

.facts-table__cross {
    background-color: var(--color-blue);
    border: calc(1rem/16) solid var(--color-text-muted);
}
.facts-table__cross:after {
    content: var(--icon-close);
}

