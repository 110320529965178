.main-navbar__overlay {
    opacity:0;
    visibility: hidden;
    position: absolute;
    width: 100%;
    background-color: var(--color-dark);
    height: calc(100vh - calc(94rem/16));
    top: calc(94rem/16);
    left:0;
    right:0;
    z-index: -1;
    transform: translateY(-100%);
    color:#fff;
    transform-origin: top;
    transition: opacity 0.25s 0.15s, visibility 0.0001s 0.4s, transform 0.4s;

    &:before {
        content:'';
        background: var(--color-primary-gradient);
        height: calc(2rem/16);
        width: 100%;
        top:0;
        position: absolute;

    }
    @media screen and (max-width: 767px) {
        bottom:0;
        height: calc(100vh - calc(62rem/16));
        top: calc(62rem/16);
    }
}

.main-navbar__overlay-container {
    padding-top: calc(95rem/16);
    @media screen and (min-width: 768px) and (max-width:1399px){
        padding-top: calc(35rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-top: calc(10rem/16);
        padding-bottom: calc(120rem/16);
        height: calc(100vh - 62px);
        overflow-y: scroll;
    }
}
.main-navbar__overlay-container.main-navbar__overlay-container--search{
    @media screen and (max-width: 767px) {
        padding: calc(100rem/16) calc(20rem/16) calc(120rem/16);
    }
}

.main-navbar__overlay.show-overlay {
    opacity:1;
    visibility: visible;
    transform: translateY(0);
    z-index: 9999;
    transition: opacity 0.2s, visibility 0.0001s, transform 0.4s;
}

.main-nav__search-overlay__input {
    height: calc(60rem/16);
    border: 1px solid var(--color-text-muted);
    display: block;
    width: 100%;
    color:#fff;
    font-size: calc(16rem/16);
    background-color: transparent;
    padding: calc(20rem/16) calc(35rem/16);
    border-radius: 0!important;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
@media screen and (min-width: 768px) {
    .main-nav__search-overlay__input:focus {
        outline: none;
        border-width: calc(2rem/16);
    }
}
.main-nav__search-overlay__input::placeholder {
    color:#fff;
}
.main-nav__search-overlay__btn {
    width: calc(60rem/16);
    height: calc(60rem/16);
    font-size: calc(24rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom:0;
    right:0;
    background-color: #fff;
    transition: transform 0.6s ease-in-out;
    @media screen and (max-width: 767px) {
        left: initial;
        right:0;
    }
}
.main-nav__search-overlay__form {
    width: calc(475rem/16);
    margin: 0 auto;
    position: relative;
    @media screen and (max-width: 767px) {
        width: auto;
    }
}
.main-navbar__overlay-close {
    position: absolute;
    left: 50%;
    top: calc(24rem/16);
    transform: translateX(-50%);
    background-color: transparent;
    color:#fff;
    font-family: var(--font-decorative);
    padding: calc(20rem/16) calc(30rem/16);
    border: none;
    z-index: 999;
    box-shadow: none;
    justify-content: center;
    display: flex;
}
.main-navbar__overlay-close__text {
    opacity:0.4;
    transition: opacity 0.2s ease;
    letter-spacing: 0.125rem;
    margin-top: -0.125rem;
    white-space: nowrap;
}
.main-navbar__overlay-close__icon {
    font-size: calc(12rem/16);
    padding-right: calc(15rem/16);
}
.main-navbar__overlay-close:hover .main-navbar__overlay-close__text {
    opacity:1;
}