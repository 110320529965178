.citation-with-image {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: calc(125rem/16) auto;
    grid-template-areas:
        "."
        "citation-with-image__body";
    @media screen and (min-width:768px) {
        grid-template-columns: 25% 75%;
        grid-template-rows: 1fr;
        gap: 0 0;
        grid-template-areas: ". citation-with-image__body";
    }
    @media screen and (min-width:1920px){
        max-width: calc(1920rem/16);
        margin: 0 auto;
    }
}
.citation-with-image__body {
    grid-area: citation-with-image__body;
    background: var(--color-white);
    position: relative;
}
.citation-with-image__image{
    position: absolute;
    width: calc(250rem/16);
    top: calc(125rem/-16);
    left: 50%;
    transform: translateX(-50%);
    @media screen and (min-width:768px){
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        width: clamp(calc(250rem/16),25vw,calc(400rem/16));
    }
}
.citation-with-image__image img {
    border-radius: 50%;
}
.citation-with-image__content {
    padding: calc(155rem/16) calc(60rem/16) calc(60rem/16) calc(60rem/16);
    @media screen and (min-width:768px){
        min-height: clamp(calc(300rem/16),40vw,calc(650rem/16));
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10% 0 22.5%;
    }
}
.citation-with-image__citation {
    font-size: calc(18rem/16);
    color: var(--color-blue);

    @media screen and (min-width:768px){
        font-size: clamp(calc(20rem/16), 2.25vw, calc(35rem/16));
    }
}
.citation-with-image__author {
    font-size: calc(16rem/16);
    color: var(--color-blue);
    font-family: var(--font-default-bold);
    margin-top: calc(24rem/16);
    padding-left: calc(40rem/16);
    position: relative;

    @media screen and (min-width:768px){
        font-size: clamp(calc(18rem/16), 1.75vw, calc(25rem/16));
    }
}
.citation-with-image__author:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: calc(3rem/16);
    width: calc(30rem/16);
    background: linear-gradient(224.16deg, #882436 0%, #CC044D 37.21%, #CC0422 100%);
}
