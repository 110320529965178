.main-navbar {
    position: relative;
    top:0;
    left:0;
    width: 100%;
    z-index: 999;
    background-color: var(--color-dark);
}
.main-navbar-nav {
    display: flex;
    background-color: var(--color-dark);
    justify-content: space-between;
    padding-left: calc(30rem/16);
    height: calc(94rem/16);

    @media screen and (max-width: 767px) {
        height: calc(62rem/16);
        padding-left: calc(13rem/16);
    }
}

.main-navbar__brand {
    display: flex;
    align-items: center;
    padding-right: calc(30rem/16);

    @media screen and (max-width: 767px) {
        padding-right: calc(5rem/16);
    }
}

.main-navbar__brand img {
    max-width: clamp(calc(120rem/16), 13vw, calc(240rem/16));
    object-fit: contain;
    @media screen and (max-width: 767px) {
        max-width: calc(150rem/16);
    }
}

.main-navbar__left, .main-navbar__right {
    display: flex;
    height: 100%;
}

.main-navbar__link-list {
    display: flex;
    @media screen and (max-width: 767px) {
        padding-right: calc(10rem/16);
    }
}

.main-navbar__link-list-item {
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
}

.main-navbar__link-icon {
    color: var(--color-text-muted);
    width: calc(20rem/16);
    margin-right: calc(8rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(17rem/16);
        padding-bottom: calc(5rem/16);
        margin-right:0;
    }
}

.main-navbar__link {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: clamp(calc(16rem/16), 1.3vw, calc(20rem/16));
    letter-spacing: calc(1rem/16);
    font-family: var(--font-decorative);

    @media screen and (max-width: 767px) {
        font-size: calc(10rem/16);
        letter-spacing: 0;
        text-align:center;
        flex-direction: column;
        text-align:center;
    }

    @media screen and (max-width: 1199px) and (min-width: 768px) {
        flex-direction: column;
    }

}

.main-navbar__btn-link {
    @media screen and (max-width: 767px) {
        border: 1px solid var(--color-blue-muted);
        font-size: calc(20rem/16);
        color: var(--color-text-default);
        font-family: var(--font-decorative);
        text-transform: uppercase;
        min-height: calc(56rem/16);
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: calc(1rem/16);
        margin-bottom: calc(5rem/16);
    }
}
.main-navbar__btn-link-icon {
    @media screen and (max-width: 767px) {
        color: var(--color-text-muted);
        font-size: calc(24rem/16);
        padding-bottom: calc(5rem/16);
        margin-right: calc(10rem/16);
    }
}