.img-teaser-grid--two-columns {
    display: grid;
    grid-template-columns: 1fr;
    gap: calc(30rem/16) 0;

    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 55% 1fr;
        grid-template-rows: 1fr;
        gap: 0 calc(30rem/16);
        grid-template-areas:
    ". .";
    }
    @media screen and (min-width: 1200px) {
        gap: 0 calc(60rem/16);
    }
}