.main-title-block__wysiwyg {
    margin-top: calc(25rem/16);
}
.main-title-block__date,
.main-title-block__location {
    display: flex;
    align-items: center;
    margin: .75rem 0;
    font-size: calc(14rem/16);
    flex-wrap: nowrap;
    flex-direction: row;
    @media screen and (min-width: 768px){
        font-size: clamp(calc(16rem/16), 1.3vw, calc(20rem/16));
    }
}
.main-title-block__icon {
    font-size: calc(22rem/16);
    padding-right: .75rem;
    @media screen and (min-width: 768px){
        font-size: calc(26rem/16);
    }
}