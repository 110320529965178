.floating-label {
    position: absolute;
    left: calc(17rem / 16);
    top: calc(15rem / 16);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
    font-size: calc(13rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-light);
}

textarea.form-control.has-value ~ .floating-label,
textarea.form-control:focus ~ .floating-label{
    transform: translateY(calc(-8rem / 16)) scale(.6);
}
.form-control:focus ~ .floating-label,
select ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-group.has-value .floating-label {
    transform: translateY(calc(-8rem / 16)) scale(.6);
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(calc(-8rem / 16)) scale(.6);
}