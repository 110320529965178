.interactive-map-filter {
    background-color: #fff;
    position: relative;
    height: calc(630rem/16);
    padding-bottom: calc(50rem/16);

    &:after {
        content:'';
        display: block;
        height: 100%;
        position: absolute;
        width: calc(50rem/16);
        background: linear-gradient(270deg, rgba(3,12,23,0) 0%, rgba(3,12,23,0.2) 100%);
        right: calc(-50rem/16);
        top:0;
        z-index: 2;
    }

    @media screen and (max-width: 767px) {
        position: absolute;
        left: 0;
        z-index: 3;
        top: 0;
        bottom: 0;
        height: auto;
        width: 60%;
        transition: transform 0.2s ease;
        transform: translateX(-100%);
    }

    &.is-open {
        transform: translateX(0%);
    }
}
.interactive-map-filter__title {
    font-size: calc(20rem/16);
    letter-spacing: calc(1.5rem/16);
    color: var(--color-default-dark);
    line-height: 1;
    width: 100%;
    font-family: var(--font-decorative);
    text-align:center;
    background-color: rgba(95,105,118,0.1);
    padding: calc(10rem/16) calc(10rem/16);
    border-bottom: calc(2rem/16) solid #fff;
    @media screen and (min-width: 768px) {
        font-size: calc(30rem/16);
        padding: calc(30rem/16) calc(15rem/16);
    }
}
.interactive-map-filter__submit {
    position: absolute;
    bottom:0;
    width: 100%;
}
.interactive-map-filter__container {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
.interactive-map-filter.is-open .interactive-map-filter__open {
    transform: scale(1);
}
.interactive-map-filter__open {
    position: absolute;
    right: calc(-30rem/16);
    background: var(--color-primary-gradient);
    color:#fff;
    z-index: 3;
    width: calc(30rem/16);
    line-height:1;
    height: calc(40rem/16);
    border: none;
    transform: scale(-1);
    box-shadow: none;
    @media screen and (min-width: 768px) {
        display: none;
    }
}