.filter-overlay {
    display: block;
}
@media screen and (max-width: 767px) {
    .filter-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
        background: var(--color-dark);
        color: #fff;
        z-index: 999;
        overflow-y: scroll;
    }
    .filter-overlay.is-open {
        transform: translateX(0);
    }
    .filter-overlay__head {
        letter-spacing: calc(2rem / 16);
        padding: calc(14rem / 16) calc(20rem / 16) calc(12rem / 16);
        height: calc(60rem / 16);
        border-bottom: calc(1rem/16) solid var(--color-light);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .filter-overlay__head__close {
        font-size: calc(14rem / 16);
    }
    .filter-overlay__content {
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: calc(30rem / 16) calc(20rem / 16);
        height: calc(100% - calc(60rem / 16));
    }
}