@font-face {
    font-family: 'Bebas Neue';
    src: url('fonts/bebas-neue-regular.woff2') format('woff2');
    font-weight: normal;
    font-display: block;
}
@font-face {
    font-family: 'Montserrat';
    src: url('fonts/montserrat-regular.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat Medium';
    src: url('fonts/montserrat-medium.woff2') format('woff2');
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat Bold';
    src: url('fonts/montserrat-bold.woff2') format('woff2');
    font-weight: 700;
    font-display: swap;
}