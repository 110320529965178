.anchor-nav{
    height: calc(40rem/16);
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #131E2C;
    z-index: 100;
    top: calc(62rem/16)!important;
    @media screen and (min-width: 768px){
        top: calc(94rem/16)!important;
        height: calc(56rem/16);
    }
}
.anchor-nav__list{
    height: calc(40rem/16);
    flex-wrap: nowrap;
    display: flex;
    max-width: 100vw;
    @media screen and (min-width: 768px){
        height: calc(56rem/16);
    }
}
.anchor-nav__link{
    height: calc(40rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: .3rem;
    margin: 0 1rem;
    white-space: nowrap;
    position: relative;
    text-transform: uppercase;
    font-family: var(--font-decorative);
    letter-spacing: calc(1rem/16);
    @media screen and (min-width: 768px){
        height: calc(56rem/16);
        font-size: clamp(calc(16rem/16), 1.3vw, calc(20rem/16));
    }
}

.anchor-nav__link:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background:linear-gradient(224.43deg, #882436 0%, #CC044D 30.62%, #CC0422 100%);;
    transform: scaleX(0);
    transition: transform 150ms ease-in;
}

.anchor-nav__link.active:after,
.anchor-nav__link:hover:after {
    transform: scaleX(1);
}


@media screen and (max-width:767px){
    .anchor-nav__list {
        overflow-x: auto;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
    }

    .anchor-nav__link{
        width: 100vw;
    }

    .anchor-nav__list-item > * {
        scroll-snap-align: start;
    }

    .anchor-nav__list::-webkit-scrollbar-track {
        background-color: var(--color-text-muted);
    }

    .anchor-nav__list::-webkit-scrollbar-thumb {
        background: var(--color-primary-gradient);
    }

    .anchor-nav__list::-webkit-scrollbar{
        height: calc(3rem/16);
    }

    .anchor-nav__link.active:after,
    .anchor-nav__link:hover:after {
        transform: scale(0);
    }
}
