
.shariff .orientation-vertical li, .shariff .orientation-vertical li a {
    display: flex;
}
.shariff .orientation-horizontal li, .shariff .orientation-horizontal li a {
    display: inline-flex;
}

.shariff-title{
    margin-bottom: 2rem;
}
.shariff ul {
    padding-left: 0;
    list-style: none;
    margin-top: 0;
}

.shariff li {
    border: 1px solid var(--color-primary);
}

.shariff li, .shariff li a {
    color: var(--color-light);
    text-align:center;
    justify-content: center;
    align-items: center;
    font-size:calc(17rem/16);
    width: calc(40rem/16);
    height:calc(40rem/16);
    transition: all 0.5s ease;
    border: none;
    background: linear-gradient(224.43deg, #CC0422 0%, #CC044D 30.62%, #882436 50%, #CC044D 69.38%, #CC0422 100%);
    background-size: 200% auto;
    line-height: 1;
    @media screen and (min-width: 768px){
        width: calc(70rem/16);
        height:calc(70rem/16);
    }
    &:hover{
        background-position: 100%;
        color: var(--color-light);
    }
}

.shariff li:hover, .shariff li:hover a {
    color: #fff;
    background-color: var(--color-primary);
}

.shariff li + li {
    margin-left: calc(12rem/16);

    @media screen and (min-width: 768px){
        margin-left: calc(24rem/16);
    }
}
.shariff-button .fab, .shariff-button .fas {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: .75rem;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);

    @media screen and (min-width: 768px){
        font-size: 1.5rem;
    }
}

.shariff-button.facebook .fab:before {
    content:var(--icon-facebook);
}

.shariff-button.twitter .fab:before {
    content:var(--icon-twitter);
}

.shariff-button.pinterest .fab:before {
    content:var(--icon-pinterest);
}

.shariff-button.mail .fas:before {
    content:var(--icon-mail);
}

.shariff-button.whatsapp .fab:before {
    content:var(--icon-whatsapp);
}

.shariff-button.addthis .fas:before {
    content:var(--icon-addthis);
}

.shariff-button.linkedin .fab:before {
    content:var(--icon-linkedin);
}