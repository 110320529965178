.vertical-teaser-text {
    background-color: #fff;
    padding: calc(15rem/16) calc(20rem/16);
    color: var(--color-text-muted);
    @media screen and (min-width: 768px){
        padding: calc(24rem/16) calc(30rem/16);
    }
}
.vertical-teaser-text__title{
    text-transform: uppercase;
    color: var(--color-dark);
    font-family: var(--font-decorative);
    font-size: calc(20rem/16);
    line-height: 1;
    margin-bottom: calc(12rem/16);
    @media screen and (min-width: 768px){
        margin-bottom: calc(8rem/16);
        font-size: clamp(calc(24rem/16), 2.75vw, calc(35rem/16));
    }
}