.badge {
    font-size: calc(16rem/16);
    padding: calc(5rem/16) calc(10rem/16);
    display: inline-block;
    letter-spacing: calc(1rem/16);
    line-height:1;
    font-family: var(--font-decorative);
}
.badge-primary {
    background: var(--color-primary-gradient);
    color:#fff;
}