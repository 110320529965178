@media screen and (min-width: 768px) {
    .mega-nav__item-content--level-2,  .mega-nav__title-link--level-2{
        color: var(--color-dark-grey);
        font-size: calc(14rem / 16);
        font-family: var(--font-default-bold);
        font-weight: var(--font-weight-bold);
        text-transform: none;
        letter-spacing: 0;
        display: block;
        padding: calc(3rem/16) 0;
        transition: color 0.2s ease;
    }

    .mega-nav--level-2 {
        margin-top: calc(10rem/16);
    }
}
