.info-modal{
    color: var(--color-dark);
}
.info-modal__content{
    border-radius: 0;
    padding: calc(40rem/16) calc(20rem/16) calc(20rem/16) calc(20rem/16);
    @media screen and (min-width: 768px){
        padding:  calc(50rem/16) calc(30rem/16) calc(20rem/16) calc(30rem/16);
    }
}
.info-modal__close{
    position: absolute;
    top: 1rem;
    right: 1rem;
}