.tour-seasons__grid {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(6,1fr);
   gap: calc(1rem/16) calc(1rem/16);
    grid-template-areas:
    ". ."
    ". ."
    ". ."
    ". ."
    ". ."
    ". .";
    @media screen and (min-width: 768px){
        grid-template-columns: repeat(6,1fr);
        grid-template-rows: repeat(2,1fr);
        grid-template-areas:
            ". . . . . ."
            ". . . . . .";
    }

}
.tour-seasons__season {
    background-color: var(--color-light);
    color: var(--color-dark);
    padding: calc(16rem/16);
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    text-align: center;
}
.tour-seasons__season--disabled {
    background-color: var(--color-blue);
    color: var(--color-text-muted);
}
.tour-seasons__title{
    margin-bottom: calc(12rem/16);
}