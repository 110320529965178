@media (hover: none) {
    .mega-nav__item.has-children > .simple-nav__item-content--link {
        display: none;
    }
    .mega-nav__item.has-no-children > .simple-nav__item-content--toggle {
        display: none;
    }
}

.mega-nav__item-content--toggle {
    display: none;
}

@media screen and (max-width: 767px) {
    .mega-nav__list--level-1, .mega-nav__teaser-wrapper {
        margin-left: calc(50rem/16);
        border-left: calc(1rem/16) solid var(--color-blue-muted);
        padding-left: calc(20rem/16);
    }
    .mega-nav--level-1 {
        background-color: var(--color-dark);
        color: var(--color-text-muted);
        z-index: 10;
        position: absolute;
        top: calc(2rem / 16);
        left: 0;
        right: 0;
        visibility: hidden;
        opacity: 0;
        height: calc(100vh - 62px);
        overflow-y: scroll;
        padding-bottom: calc(120rem/16);
    }
    .mega-nav--level-1__go-back {
        box-shadow: none;
        border: none;
        padding:0;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: transparent;
    }
    .mega-nav--level-1__go-back__icon {
        background-color: var(--color-blue-muted);
        color:#fff;
        width: calc(50rem/16);
        height: calc(75rem/16);
        box-shadow: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border: calc(1rem/16) solid var(--color-blue-muted);
    }
    .mega-nav--level-1__go-back__text-icon {
        color: var(--color-text-muted);
        font-size: calc(25rem/16);
        padding-left: calc(25rem/16);
        padding-right: 1rem;
    }
    .mega-nav--level-1__go-back__text {
        font-size: calc(20rem/16);
        font-family: var(--font-decorative);
        line-height: calc(40/20);
        color:#fff;
    }
    .mega-nav__item.is-open > .mega-nav--level-1 {
        opacity: 1;
        visibility: visible;
        background-color: var(--color-dark);
    }
    .mega-nav__item-content-icon {
        color: var(--color-text-muted);
        font-size: calc(25rem/16);
        width: calc(45rem/16);
        margin-right: calc(5rem/16);
    }
    .mega-nav__title--level-1, .mega-nav__item--level-1, .mega-nav__item-content--level-0 {
        padding: 1.5rem 0;
    }
    .mega-nav__item-content--level-2,  .mega-nav__title-link--level-2{
        color: var(--color-dark-grey);
        font-size: calc(14rem / 16);
        font-family: var(--font-default-bold);
        text-transform: none;
        letter-spacing: 0;
        display: block;
        padding: calc(5rem/16) 0;
        transition: color 0.2s ease;
    }
}

