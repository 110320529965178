.barrierfree-info__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}
@media screen and(min-width:768px){
    .barrierfree-info__item:after{
        position: absolute;
        content: '';
        width: calc(44rem/16);
        transition: transform 0.25s ease-in;
        background-color: #fff;
        top: 0;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        z-index: -1;
    }
    .barrierfree-info__item:focus:after,
    .barrierfree-info__item:hover:after{
        transform: scaleX(1);
    }
}
.barrierfree-info__icon {
    font-size: 2.75rem;
    color: #cc044d;
    @media screen and (min-width:768px){
        margin-right: 1rem;
    }
}
.barrierfree-info__info-button{
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    border-radius: 50%;
    border: calc(1rem/16) solid #fff;
    position: relative;
    margin-left: .5rem;
    margin-bottom: -.25rem;
}
.barrierfree-info__info-button__i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(10rem/16);
}

