.tour-facts__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: .5rem .5rem;
    grid-template-areas:
    ". ."
    ". ."
    ". .";
    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0 0;
        grid-template-areas:
    ". . . . . .";
    }
}

.tour-facts__title {
    margin-bottom: calc(12rem / 16);
}

.tour-facts__item {
    line-height: 1.2;
}

.tour-facts__description {
    color: var(--color-text-muted);
    font-family: var(--font-default-bold);
    font-size: calc(16rem / 16);
    @media screen and (min-width: 768px) {
        font-size: clamp(calc(16rem / 16), 1.25vw ,calc(20rem / 16));
    }
}