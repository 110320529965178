.portal-hero__ratio:before {
    padding-top: 100%;

    @media screen and (min-width: 768px) {
        padding-top: calc(100vh - var(--nav-height));
    }
    @media screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
        padding-top: calc(80vh - var(--nav-height--mobile));
    }
}
.portal-hero__img {
    @media screen and (min-width: 768px) {
        object-fit: cover;
        object-position: right;
    }
}
.portal-hero__img-wrapper {
    overflow: hidden;
}
.portal-hero__mask {
    object-fit: cover;
    object-position: 70% 50%;
    @media screen and (min-width: 768px) {
        object-position: right;
    }
}
.portal-hero {
    position: relative;
    margin-top: calc(-1rem/16);
}
.portal-hero:after {
    content:'';
    position: absolute;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(3,12,23,0.4) 70%, rgba(3,12,23,1) 100%);
    top:0;
    bottom:0;
    width: 100%;
    height: 100%;
}