.congress-title-block__title{
    margin-bottom: calc(8rem/16);
}

.congress-title-block__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: calc(10rem/16);
    @media screen and (min-width:768px){
        align-items: center;
        margin-bottom: calc(18rem/16);
        font-size: clamp(calc(16rem/16), 1.125vw, calc(20rem/16));
    }
}

.congress-title-block__icon {
    margin-right: calc(10rem/16);
    font-size: calc(16rem/16);
    @media screen and (min-width:768px){
        font-size: calc(26rem/16);
    }
}