.container.container {
    max-width: calc(var(--container-max-width) + var(--container-padding));
    width: 100%;
}
.container--no-padding-mobile {
    @media screen and(max-width: 767px) {
        padding-left:0;
        padding-right:0;
    }
}
.container--fullwidth {
    max-width: 100vw;
    width: 100%;
    padding: 0;
}
.container--md{
    margin: 0 auto;
    max-width: calc(var(--container-md-max-width) + var(--container-padding));
    width: 100%;
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
}
.container--lg{
    margin: 0 auto;
    max-width: calc(var(--container-lg-max-width) + var(--container-padding));
    width: 100%;
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
}

.container--move-right {
    width: 100%;
    padding-left: var(--container-padding);
    @media screen and (max-width: 767px) {
        padding-right: var(--container-padding);
    }
    @media screen and (min-width: 1665px) and (max-width: 1920px){
        margin-left: auto;
        padding-left:0;
        padding-right:0;
        max-width: calc(var(--container-max-width) + ((100vw - var(--container-max-width)) / 2));
    }
    @media screen and (min-width: 1921px) {
        margin: 0 auto;
        padding-left:0;
        padding-right:0;
        max-width: calc(var(--container-max-width) + ((1920px - var(--container-max-width)) / 2));
    }
}

@media screen and (max-width:767px){
    .container .container,
    .container .container--md,
    .container .container--lg {
        padding: 0;
    }
}
