.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 1.2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    border: none;
    position: relative;
    box-shadow: none;
    overflow: hidden;
    padding: calc(3rem/16) 0;
    background: linear-gradient(224.43deg, #882436 0%, #CC044D 30.62%, #CC0422 100%) repeat-x 0 100%;
    background-size: calc(2rem / 16) calc(2rem / 16);
    text-decoration: none;
    transition: background-size .2s ease-in;
    cursor: pointer;

    &:hover {
        background-size: calc(2rem / 16) 100%;
    }
}
.wysiwyg {
    line-height:2;
}

.wysiwyg b,
.wysiwyg big,
.wysiwyg bold,
.wysiwyg strong,{
    font-family: var(--font-default-ultra-bold);
}

.wysiwyg--text-small{
    margin-top: calc(12rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(16rem/16);
        font-size: clamp(calc(12rem/16), 1.25vw, calc(16rem/16));
    }
}
.wysiwyg__read-more {
    border: none;
    background-color: transparent;
    color: currentColor;
    box-shadow: none;
    position: relative;
    overflow: hidden;

    &:before {
        content:'';
        position: absolute;
        bottom:0;
        height: 100%;
        transform: translateY(calc(100% - calc(2rem/16)));
        width: 100%;
        left:0;
        z-index: -1;
        transition: transform 0.2s ease;
        background: var(--color-primary-gradient);
    }

    &:hover:before {
        transform: translateY(0);
    }
}
.wysiwyg__read-more.collapsed .wysiwyg__read-more-text, .wysiwyg__read-more-text--collapsed {
    display: none;
}
.wysiwyg__read-more.collapsed .wysiwyg__read-more-text--collapsed {
    display: block;
}
.wysiwyg__read-more-detail {
    padding-top: calc(20rem/16);
}

/* lists */
.wysiwyg ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.wysiwyg ul li {
    padding-left: 1.5rem;
    padding-bottom: 0.4rem;
    position: relative;
}

.wysiwyg ul li:before {
    position: absolute;
    left: 0;
    top: calc(7rem/16);
    content: "";
    width: calc(8rem/16);
    height: calc(8rem/16);
    background-color: var(--color-primary);
    @media screen and (min-width: 768px){
        top: calc(11rem/16);
    }
}