.feature-list__icon {
    font-size: calc(20rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(24rem/16);
    }
}
.feature-list__item {
    display: grid;
    grid-template-columns: calc(30rem/16) 1fr;
    gap: 0 calc(10rem/16);
    font-size: calc(14rem/16);
    line-height: calc(28/20);
    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
}