.img-teaser {
    position: relative;
    overflow: hidden;
}
.img-teaser__body {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    padding: calc(25rem/16) calc(20rem/16);
    z-index: 2;
    @media screen and (min-width: 768px) {
        padding: calc(30rem/16) calc(25rem/16);
    }
}
.img-teaser__img-wrapper:after {
    background: linear-gradient(0deg, rgba(3,12,23,0) 0%, rgba(3, 12, 23, 0.9) 100%);
    position: absolute;
    content: '';
    transition: opacity 0.2s ease;
    opacity: 0.6;
    width: 100%;
    height: 60%;
    top:0;
    left:0;
    z-index: 1;
    @media screen and (min-width: 768px) {
        background: linear-gradient(0deg, rgba(3,12,23,0) 0%, rgba(3, 12, 23, 0.6) 100%);
    }
}
.img-teaser--dark .img-teaser__img-wrapper:after{
    @media screen and (min-width: 768px) {
        background: linear-gradient(0deg, rgba(3,12,23,0) 0%, rgba(3, 12, 23, 1) 100%);
        height: 100%;
    }
}
.img-teaser__img-wrapper {
    background-color:#fff;
}
.img-teaser__img--logo {
    padding: calc(30rem/16);
}
.img-teaser__title {
    font-family: var(--font-decorative);
    font-size: calc(25rem/16);
    margin-bottom:0;
    line-height: 1.1;
    @include hyphenation(6, 3, 3);

    @media screen and (min-width: 768px) {
        font-size: clamp(calc(20rem/16), 1.75vw, calc(32rem/16));
    }
}
.img-teaser--small .img-teaser__title {
    font-size: calc(25rem/16);
    @media screen and (min-width: 768px) {
        font-size: clamp(calc(18rem/16), 2vw, calc(30rem/16));
    }
}
.img-teaser__sub-title {
    font-size: calc(14rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}
.img-teaser__additional-info {
    font-size: calc(14rem/16);
}
.img-teaser__additional-info-icon {
    width: calc(25rem/16);
    font-size: calc(18rem/16);
}
.img-teaser__additional-info--small {
    font-size: calc(11rem/16);

    .img-teaser__additional-info-icon {
        width: calc(17rem/16);
        font-size: calc(13rem/16);
    }
}
.img-teaser__background {
    background: linear-gradient(224.16deg, #882436 0%, #CC044D 37.21%, #CC0422 100%);
}
.img-teaser__tour {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: calc(12rem/16);
    @media screen and (min-width: 768px) {
        font-size: clamp(calc(10rem/16), 1.25vw, calc(18rem / 16));
    }
}
.img-teaser__tour__item:not(:last-child) {
    padding-right: 1.5rem;
    position: relative;
    @media screen and (min-width: 768px) {
        padding-right: clamp(calc(24rem/16), 3vw, calc(40rem / 16));
    }
}
.img-teaser__tour__item:not(:last-child):after{
    position: absolute;
    content: "";
    top: calc(4rem/16);
    bottom: calc(4rem/16);
    right: .75rem;
    width: calc(1rem/16);
    background-color: var(--color-light);
    @media screen and (min-width: 768px) {
        top: calc(8rem/16);
        bottom: calc(8rem/16);
        right: clamp(0.75rem, 3.25vw, 1.25rem);
    }
}
.img-teaser__tour__title {
    font-size: calc(18rem/16);
    line-height: 1.1;
    font-family: var(--font-decorative);
    @media screen and (min-width: 768px) {
        font-size: clamp(calc(18rem/16), 1.5vw, calc(30rem / 16));
    }
}
.img-teaser__author {
    width: calc(40rem/16);
    border-radius: 50%;
    border: calc(2rem/16) solid #fff;
    overflow: hidden;
}
.img-teaser:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    opacity: 0.5;
    z-index: 2;
    background: linear-gradient(0deg, rgba(3,12,23,0) 0%, rgba(3, 12, 23, 0.8) 100%);
    transition: opacity 0.3s ease;
}
.img-teaser__title-wrapper {
    color: var(--color-text-default);
    transition: transform 0.3s ease;
}
@media screen and (min-width: 768px) {
    .img-teaser__img {
        transition: 0.6s ease;
    }
    .img-teaser:hover .img-teaser__img {
        transform: scale(1.05);
    }
    .img-teaser:hover:before {
        opacity:0.8;
    }
}