.address-block__title {
    margin-bottom: calc(30rem/16);
}
.address-block__label {
    color: var(--color-text-muted);
    letter-spacing: calc(1rem/16);
    line-height: calc(19/16);
    margin-bottom: calc(8rem/16);
}
.address-block__text {
    letter-spacing: calc(1rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
.address-block__map {
    margin-top: calc(40rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(100rem/16);
    }
}