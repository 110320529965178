.custom-cursor {
    position: fixed;
    pointer-events: none;
    z-index: 90000;
    mix-blend-mode: screen;
    top: 0;
    left: 0;
}
.custom-cursor:after {
    content: '';
    display: block;
    border-radius: 50%;
    background: var(--color-primary);
    transition: transform .3s ease, opacity .3s ease;
    position: absolute;
    top: calc(50rem / 16 / -2);
    left: calc(50rem / 16 / -2);
    pointer-events: none;
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    transform-origin: center center;
    transform: scale(0.2);
}
.custom-cursor.is-hover:after {
    opacity: .8;
    transform: scale(1);
}
.custom-cursor.is-active:after {
    opacity: .5;
}
.custom-cursor.has-zoom {
    mix-blend-mode: initial;
    cursor:none;

    &:after {
        transform: scale(1.7);
        opacity:0.7;
        content:var(--icon-search);
        font-family:iconfont;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:calc(-15rem/16);
        margin-left: calc(-15rem/16);
        font-size: calc(27rem/16);
    }
}
.custom-cursor.is-hover.is-active:after {
    transform: scale(.8);
}

@media (hover: none) and (pointer:coarse) {
    .custom-cursor {
        display:none
    }
}
