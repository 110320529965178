.cutout-hero {
    position: relative;
}
.cutout-hero__ratio:before {
    padding-top: 40%;

    @media screen and (min-width: 768px) {
        padding-top: 20%;
    }
}
.cutout-hero__img-wrapper {
    overflow: hidden;
}
.cutout-hero__cutout-text {
    position: absolute;
    color: #fff;
    mix-blend-mode: darken;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    letter-spacing: 1vw;
    font-size: clamp(5.625rem,24vw,27rem);
    margin: auto;
    font-family: var(--font-decorative);
    text-transform: uppercase;
    user-select: none;

    &:before {
        content:'';
        mix-blend-mode: color;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(3,12,23,1) 0%, rgba(3,12,23,0.8) 26%, rgba(3,12,23,0.8) 80%, rgba(3,12,23,1) 100%);
    }

    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        background: linear-gradient(180deg, rgba(3, 12, 23, 0.8) 100%, #030C17 100%);
        width: 100%;
        height: 100%;
        opacity:0.6;
    }
}
.cutout-hero__cutout-text--small{
    font-size: clamp(4rem,22vw,30rem);
    line-height: 2;
    letter-spacing: 0;
}
.cutout-hero__title {
    text-align:center;
    padding: calc(20rem/16);
    text-shadow: 0 calc(10rem/16) calc(15rem/16) var(--color-dark);

    @media screen and (max-width: 767px) {
        font-size: calc(24rem/16);
    }
}
.cutout-hero__subtitle{
    margin-bottom: 0;
}
.cutout-hero__content {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.cutout-hero__content.cutout-hero__content--bottom{
    top: unset;
    bottom: 0;
    height: unset;
}