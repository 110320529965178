.tour-hero {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
    gap: 0 0;
    grid-template-areas: "tour-hero__map tour-hero__image";

    @media screen and (min-width:1920px){
        max-width: calc(1920rem/16);
        margin: 0 auto;
    }
}