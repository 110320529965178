.tour-rating__title{
    margin-bottom: calc(12rem/16);
}
.tour-rating__item-title{
    margin-bottom: calc(8rem/16);
    @media screen and (min-width: 768px){
        margin-bottom: calc(12rem/16);
    }
}
.tour-rating__icon {
    margin-right: calc(10rem/16);
    font-size: calc(18rem/16);
}
.tour-rating__icon--filled{
    color: var(--color-primary);
}