.content-block + .content-block,
.content-block--sm + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.pimcore_area_content + .content-block{
    margin-top: calc(45rem/16);

    @media screen and (min-width: 768px) {
        margin-top: clamp(calc(60rem/16), 9vw, calc(160rem/16));
    }
}


.content-block--lg + .content-block--lg,
.content-block--lg + .pimcore_area_content > .content-block--lg,
.pimcore_area_content + .pimcore_area_content > .content-block--lg {
    margin-top: calc(60rem/16);

    @media screen and (min-width: 768px) {
        margin-top: clamp(calc(80rem/16), 13vw, calc(250rem/16));
    }
}

.content-block--sm + .content-block--sm,
.content-block--sm + .pimcore_area_content > .content-block--sm,
.content-block + .content-block--sm,
.content-block + .pimcore_area_content > .content-block--sm,
.pimcore_area_content + .pimcore_area_content > .content-block--sm {
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(64rem/16);
    }
}