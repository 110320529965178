.link-underlined {
    border: none;
    position: relative;
    background-color: transparent;
    color: currentColor;
    box-shadow: none;
    overflow: hidden;
    padding: calc(3rem/16) 0;
    background-image: var(--color-primary-gradient);
    background-repeat: no-repeat;
    transition: background-size 0.3s ease, color 0.3s ease;
    background-size: 100% 2px;
    background-position: bottom;

    @media screen and (min-width: 768px) {
        &:hover {
            color: #fff;
            background-size: 100% 100%;
        }
    }
}