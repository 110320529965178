.nav-tabs {
    font-size: calc(12rem/16);
    letter-spacing: calc(0.83rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(30rem/16);
    justify-content: center;

    @media screen and (max-width: 767px) {
        display: inline-block;
        width: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
        margin-bottom: calc(15rem/16);
    }

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
.nav-tabs .nav-item {
    @media screen and (max-width: 767px) {
        display: inline-block;
    }
}
.nav-tabs .nav-link {
    @media screen and (max-width: 767px) {
        padding: calc(5rem/16) calc(8rem/16);
        font-size: calc(16rem/16);
    }
}

.nav-tabs .nav-link.active {
    position: relative;

    &:before {
        content:'';
        position: absolute;
        height: calc(2rem/16);
        width: 100%;
        bottom:calc(-2rem/16);
        left:0;
        background: var(--color-primary-gradient);
        @media screen and (max-width:767px){
            display: none;
        }
    }
    @media screen and (max-width:767px){
        background-color: #ffffff15;
    }
}

.nav-link__sub-text {
    font-size: calc(9rem/16);
    font-family: var(--font-default);
    display: block;

    @media screen and (min-width: 768px) {
        font-size: calc(12rem/16);
    }
}

.nav-link__title {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}

/*-------------- full width tabs -------------*/
.nav-tabs--full-width {
    font-family: var(--font-decorative);
    color: var(--color-text-muted);
    font-size: calc(18rem/16);
    justify-content: space-between;

    @media screen and (min-width: 768px) {
        font-size: calc(24rem/16);
        letter-spacing: calc(1rem/16);
    }
}