.teaser__barrierfree-area__icon {
    font-size: calc(36rem/16);
    padding: .25rem;
    @media screen and(min-width: 768px){
        font-size: clamp(calc(30rem/16), 2.75vw, calc(44rem/16));
        padding: 0;
    }
}
.img-teaser .teaser__barrierfree-area__btn:hover {
    color: #fff;
}
.img-teaser .teaser__barrierfree-area__btn:after {
    position: absolute;
    content: '';
    width: calc(44rem/16);
    transition: transform 0.25s ease-in;
    background-color: var(--color-dark);
    top: 0;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    z-index: -1;
}
.img-teaser .teaser__barrierfree-area__btn:focus:after,
.img-teaser .teaser__barrierfree-area__btn:hover:after{
    transform: scaleX(1);
}