
.hotel-teaser {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.hotel-teaser--package .hotel-teaser__body {
    padding: calc(15rem / 16) calc(20rem / 16);
    @media screen and (min-width: 768px) {
        padding: calc(24rem / 16) calc(30rem / 16);
    }
}

.hotel-teaser__body {
    background-color: #fff;
    padding: calc(20rem / 16);
    color: var(--color-text-muted);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16);
    }
}

.hotel-teaser__title {
    color: var(--color-blue);
    font-size: calc(20rem / 16);
    font-family: var(--font-decorative);
    line-height: 1;
    @media screen and (min-width: 768px) {
        font-size: clamp(calc(20rem / 16), 2vw, calc(32rem / 16));
        letter-spacing: calc(1rem / 16);
        margin-bottom: calc(8rem / 16);
    }
}

.hotel-teaser__icon {
    margin-right: calc(8rem / 16);
    font-size: calc(16rem / 16);
    margin-bottom: calc(2rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
        margin-bottom: calc(4rem / 16);
        margin-right: calc(12rem / 16);
    }
}

.hotel-teaser__button {
    width: fit-content;
}

.hotel-teaser__offer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border-top: calc(1rem / 16) solid var(--color-grey);
    padding: calc(15rem / 16) calc(20rem / 16);
    background-color: #fff;
    color: var(--color-text-muted);
    line-height: 1.25;
    flex-wrap: wrap;
    @media screen and (min-width: 768px) {
        padding: calc(24rem / 16) calc(30rem / 16);
    }
}

.hotel-teaser__price {
    color: var(--color-blue);
    font-family: var(--font-decorative);
    font-size: clamp(calc(20rem / 16), 2.25vw, calc(30rem / 16));
    margin: 0 calc(3rem / 16);
}