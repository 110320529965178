.hotel-sticky-bar {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-light);
    color: var(--color-blue);
    padding: 1rem 0;
    z-index: 3;
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}

.hotel-sticky-bar__title {
    font-size: calc(22rem / 16);
    font-family: var(--font-default-bold);
}

.hotel-sticky-bar__item {
    display: flex;
    align-items: center;
    margin-right: calc(30rem / 16);
}

.hotel-sticky-bar__icon {
    margin-right: calc(8rem / 16);
    font-size: calc(20rem / 16);
}

.hotel-sticky-bar--desktop {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.hotel-sticky-bar--mobile {
    @media screen and (min-width: 768px) {
        display: none;
    }
}