.card-teaser__img {
    object-fit: contain;
}
.card-teaser__img-wrapper {
    background-color: var(--color-dark-blue);

    &:after {
        content:'';
        position: absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 30%;
        z-index: 2;
        display: block;
        background: linear-gradient(180deg, rgba(3,12,23,0) 0%, rgba(3,12,23,0.8) 100%);
    }
}
.card-teaser__copyright {
    position: absolute;
    right:0;
    width: auto;
    height: auto;
    left: initial;
    background-color: var(--color-dark-blue);
    font-size: calc(10rem/16);
    text-align: right;
    z-index: 3;
    padding: calc(7rem/16);
}

.card-teaser__copyright.card-teaser__copyright--bottom {
    bottom: 0;
    top: unset;
}

.card-teaser__body {
    background-color:#fff;
    color: var(--color-blue);
    padding: calc(15rem/16) calc(20rem/16);
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px) {
        padding: calc(27rem/16) calc(25rem/16);
        min-height: calc(225rem/16);
    }
}
.card-teaser__title {
    font-family: var(--font-decorative);
    font-size: calc(25rem/16);
    letter-spacing: 0;
    @media screen and (min-width: 768px) {
        font-size: calc(32rem/16);
    }
}
.card-teaser-modal {
    position: relative;
}
.card-teaser-modal__arrow {
    background: transparent;
    color:#fff;
    z-index: 1099;
    border: none;
    box-shadow: none;
    font-size: calc(30rem/16);
    position: absolute;
    top:50%;
    transform: translateY(-50%);
}
.card-teaser-modal__next {
    right:0;
}