.video-overlay__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.9);
    box-shadow: 0 0 0 0 #dcdcdc;
    transition: all 350ms ease;
    z-index: 4;
    cursor: pointer;
    width: calc(64rem/16);
    height: calc(64rem/16);
    background: var(--color-primary-gradient);
    border-radius: 50%;
    color: var(--color-light);
    @media screen and (min-width:768px){
        width: calc(128rem/16);
        height: calc(128rem/16);
    }
}
.video-overlay__icon {
    position: absolute;
    top: 32%;
    left: 44.5%;
    font-size: calc(24rem/16);
    transition: transform 200ms ease;
    @media screen and (min-width:768px){
        font-size: calc(48rem/16);
    }
}

.video-overlay {
    cursor: pointer;
}

.video-overlay:hover .video-overlay__play-button {
   transform: translate(-50%, -50%) scale(1);
}
.video-overlay__overlay {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.overlay-hide .video-overlay__overlay,
.overlay-hide .video-overlay__play-button,
.video-overlay:after {
    display: none;
    pointer-events: auto;
}

.video-overlay.overlay-hide{
    pointer-events: auto;
}