.form-errors.invalid-feedback {
    font-size: calc(12rem/16);
    background: var(--color-danger);
    color: #fff;
    width: auto;
    display: inline-block;
    padding: calc(2rem/16) calc(10rem/16);
    border-radius: calc(2rem/16);
}
.form-errors:empty,
.is-valid .form-errors {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.pristine-error {
    color: var(--color-danger);
    font-size: calc(10rem/16);
    font-family: var(--font-default-bold);
}
.pristine-group.has-danger {
    border: 1px solid var(--color-danger);
    position: relative;
}
.pristine-group .pristine-error {
    padding-top: calc(10rem/16);
}