@font-face {
    font-family: "iconfont";
    src: url('static/icons/font/iconfont.woff2') format('woff2'),
    url('static/icons/font/iconfont.woff') format('woff'),
    url('static/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-2-crystals:before { content: "\EA01" }
.icon-2-stars:before { content: "\EA02" }
.icon-2flowers:before { content: "\EA03" }
.icon-3-crystals:before { content: "\EA04" }
.icon-3-stars-s:before { content: "\EA05" }
.icon-3-stars:before { content: "\EA06" }
.icon-3flowers:before { content: "\EA07" }
.icon-4-crystals:before { content: "\EA08" }
.icon-4-stars-s:before { content: "\EA09" }
.icon-4-stars:before { content: "\EA0A" }
.icon-4flowers:before { content: "\EA0B" }
.icon-5-stars:before { content: "\EA0C" }
.icon-address:before { content: "\EA0D" }
.icon-addthis:before { content: "\EA0E" }
.icon-apartment:before { content: "\EA0F" }
.icon-arrow-left:before { content: "\EA10" }
.icon-arrow-right:before { content: "\EA11" }
.icon-back:before { content: "\EA12" }
.icon-bankett:before { content: "\EA13" }
.icon-barrier-free:before { content: "\EA14" }
.icon-barrierfree:before { content: "\EA15" }
.icon-bed:before { content: "\EA16" }
.icon-bicycle:before { content: "\EA17" }
.icon-blind:before { content: "\EA18" }
.icon-busparking:before { content: "\EA19" }
.icon-calendar:before { content: "\EA1A" }
.icon-camping:before { content: "\EA1B" }
.icon-category:before { content: "\EA1C" }
.icon-changing-table:before { content: "\EA1D" }
.icon-check:before { content: "\EA1E" }
.icon-close:before { content: "\EA1F" }
.icon-creditcard:before { content: "\EA20" }
.icon-download:before { content: "\EA21" }
.icon-dropdown:before { content: "\EA22" }
.icon-elements:before { content: "\EA23" }
.icon-empfang:before { content: "\EA24" }
.icon-erolli:before { content: "\EA25" }
.icon-excursion:before { content: "\EA26" }
.icon-facebook:before { content: "\EA27" }
.icon-familien-mit-kleinkind:before { content: "\EA28" }
.icon-fb:before { content: "\EA29" }
.icon-ferienwohnung:before { content: "\EA2A" }
.icon-fruhstuck:before { content: "\EA2B" }
.icon-gastro:before { content: "\EA2C" }
.icon-gehbehindert:before { content: "\EA2D" }
.icon-gehoerlos:before { content: "\EA2E" }
.icon-genusshauptstadt:before { content: "\EA2F" }
.icon-group:before { content: "\EA30" }
.icon-handy:before { content: "\EA31" }
.icon-hotel:before { content: "\EA32" }
.icon-insta:before { content: "\EA33" }
.icon-kongress_info:before { content: "\EA34" }
.icon-kongress_locations:before { content: "\EA35" }
.icon-kongress_service:before { content: "\EA36" }
.icon-late-kitchen:before { content: "\EA37" }
.icon-lebensmittelallergie:before { content: "\EA38" }
.icon-lernschwierigkeiten:before { content: "\EA39" }
.icon-linkedin:before { content: "\EA3A" }
.icon-maestro:before { content: "\EA3B" }
.icon-mail:before { content: "\EA3C" }
.icon-menue:before { content: "\EA3D" }
.icon-museum:before { content: "\EA3E" }
.icon-no-category:before { content: "\EA3F" }
.icon-opened:before { content: "\EA40" }
.icon-outdoor-restaurant:before { content: "\EA41" }
.icon-package:before { content: "\EA42" }
.icon-parking:before { content: "\EA43" }
.icon-parlament:before { content: "\EA44" }
.icon-people:before { content: "\EA45" }
.icon-phone:before { content: "\EA46" }
.icon-pinterest:before { content: "\EA47" }
.icon-planning:before { content: "\EA48" }
.icon-play:before { content: "\EA49" }
.icon-playground:before { content: "\EA4A" }
.icon-plus:before { content: "\EA4B" }
.icon-pollen-stauballergie:before { content: "\EA4C" }
.icon-privatzimmer:before { content: "\EA4D" }
.icon-reisemobil-stellplatz:before { content: "\EA4E" }
.icon-restaurant-2:before { content: "\EA4F" }
.icon-restaurant:before { content: "\EA50" }
.icon-rolli-mit-hilfe:before { content: "\EA51" }
.icon-search:before { content: "\EA52" }
.icon-sehbehindert:before { content: "\EA53" }
.icon-sightseeing:before { content: "\EA54" }
.icon-slide-left:before { content: "\EA55" }
.icon-slide-right:before { content: "\EA56" }
.icon-sport:before { content: "\EA57" }
.icon-star-full:before { content: "\EA58" }
.icon-star-outline:before { content: "\EA59" }
.icon-theater:before { content: "\EA5A" }
.icon-time:before { content: "\EA5B" }
.icon-travel:before { content: "\EA5C" }
.icon-twitter:before { content: "\EA5D" }
.icon-web:before { content: "\EA5E" }
.icon-whatsapp:before { content: "\EA5F" }
.icon-youtube:before { content: "\EA60" }


:root {
--icon-2-crystals: "\EA01";
    --icon-2-stars: "\EA02";
    --icon-2flowers: "\EA03";
    --icon-3-crystals: "\EA04";
    --icon-3-stars-s: "\EA05";
    --icon-3-stars: "\EA06";
    --icon-3flowers: "\EA07";
    --icon-4-crystals: "\EA08";
    --icon-4-stars-s: "\EA09";
    --icon-4-stars: "\EA0A";
    --icon-4flowers: "\EA0B";
    --icon-5-stars: "\EA0C";
    --icon-address: "\EA0D";
    --icon-addthis: "\EA0E";
    --icon-apartment: "\EA0F";
    --icon-arrow-left: "\EA10";
    --icon-arrow-right: "\EA11";
    --icon-back: "\EA12";
    --icon-bankett: "\EA13";
    --icon-barrier-free: "\EA14";
    --icon-barrierfree: "\EA15";
    --icon-bed: "\EA16";
    --icon-bicycle: "\EA17";
    --icon-blind: "\EA18";
    --icon-busparking: "\EA19";
    --icon-calendar: "\EA1A";
    --icon-camping: "\EA1B";
    --icon-category: "\EA1C";
    --icon-changing-table: "\EA1D";
    --icon-check: "\EA1E";
    --icon-close: "\EA1F";
    --icon-creditcard: "\EA20";
    --icon-download: "\EA21";
    --icon-dropdown: "\EA22";
    --icon-elements: "\EA23";
    --icon-empfang: "\EA24";
    --icon-erolli: "\EA25";
    --icon-excursion: "\EA26";
    --icon-facebook: "\EA27";
    --icon-familien-mit-kleinkind: "\EA28";
    --icon-fb: "\EA29";
    --icon-ferienwohnung: "\EA2A";
    --icon-fruhstuck: "\EA2B";
    --icon-gastro: "\EA2C";
    --icon-gehbehindert: "\EA2D";
    --icon-gehoerlos: "\EA2E";
    --icon-genusshauptstadt: "\EA2F";
    --icon-group: "\EA30";
    --icon-handy: "\EA31";
    --icon-hotel: "\EA32";
    --icon-insta: "\EA33";
    --icon-kongress_info: "\EA34";
    --icon-kongress_locations: "\EA35";
    --icon-kongress_service: "\EA36";
    --icon-late-kitchen: "\EA37";
    --icon-lebensmittelallergie: "\EA38";
    --icon-lernschwierigkeiten: "\EA39";
    --icon-linkedin: "\EA3A";
    --icon-maestro: "\EA3B";
    --icon-mail: "\EA3C";
    --icon-menue: "\EA3D";
    --icon-museum: "\EA3E";
    --icon-no-category: "\EA3F";
    --icon-opened: "\EA40";
    --icon-outdoor-restaurant: "\EA41";
    --icon-package: "\EA42";
    --icon-parking: "\EA43";
    --icon-parlament: "\EA44";
    --icon-people: "\EA45";
    --icon-phone: "\EA46";
    --icon-pinterest: "\EA47";
    --icon-planning: "\EA48";
    --icon-play: "\EA49";
    --icon-playground: "\EA4A";
    --icon-plus: "\EA4B";
    --icon-pollen-stauballergie: "\EA4C";
    --icon-privatzimmer: "\EA4D";
    --icon-reisemobil-stellplatz: "\EA4E";
    --icon-restaurant-2: "\EA4F";
    --icon-restaurant: "\EA50";
    --icon-rolli-mit-hilfe: "\EA51";
    --icon-search: "\EA52";
    --icon-sehbehindert: "\EA53";
    --icon-sightseeing: "\EA54";
    --icon-slide-left: "\EA55";
    --icon-slide-right: "\EA56";
    --icon-sport: "\EA57";
    --icon-star-full: "\EA58";
    --icon-star-outline: "\EA59";
    --icon-theater: "\EA5A";
    --icon-time: "\EA5B";
    --icon-travel: "\EA5C";
    --icon-twitter: "\EA5D";
    --icon-web: "\EA5E";
    --icon-whatsapp: "\EA5F";
    --icon-youtube: "\EA60";
    
}