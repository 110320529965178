/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-blue { color: var(--color-blue); }
.text-dark { color: var(--color-dark);}

/* Background Color Helper */
.bg-primary-gradient {background: var(--color-primary-gradient)}
.bg-dark {background-color: var(--color-dark)}
.bg-dark-blue {background-color: var(--color-dark-blue)}
.bg-middle-blue {background-color: var(--color-middle-blue)}
.bg-blue {background-color: var(--color-blue)}
.bg-grey {background-color: var(--color-grey)}
.bg-light {background-color: var(--color-light)}

/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.font-default { font-family: var(--font-default)}
.font-default-bold, strong { font-family: var(--font-default-bold)}
.font-decorative {font-family: var(--font-decorative);}

.pt-md-gutter {
    @media screen and (min-width: 768px) {
        padding-top: var(--grid-gutter);
    }
}
.mt-md--gutter {
    @media screen and (min-width: 768px) {
        margin-top: calc(-1 * var(--grid-gutter));
    }
}

.letter-spacing-1 {
    letter-spacing: calc(1rem/16);
}

.z-100 {
    z-index: 100;
}

.fs-14{
    font-size: calc(14rem/16);
}

.fs-20{
    font-size: calc(20rem/16);
}

.border-muted {
    border: 1px solid var(--color-text-muted)!important;
}

.booking__iframe-wrapper iframe {
    @media screen and (min-width: 768px){
        min-height: calc(270rem /16)!important;
    }
}