.vertical-teaser {
    display: grid;
    background-color: #fff;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: 0 0;
    grid-template-areas:
    "vertical-teaser__image__wrapper"
    "vertical-teaser__content";

    @media screen and(min-width:768px){
        grid-template-columns: 0.525fr 2fr;
        grid-template-rows: 1fr;
        gap: 0 0;
        grid-template-areas:
    "vertical-teaser__image__wrapper vertical-teaser__content";
    }
}
.vertical-teaser--package .vertical-teaser__content{
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.vertical-teaser:hover .vertical-teaser__image{
    transform: scale(1.05);
}
.vertical-teaser .vertical-teaser__image{
    transition: transform 0.4s ease-in;
    object-fit: cover;
}
.vertical-teaser__image__wrapper {
    grid-area: vertical-teaser__image__wrapper;
    overflow: hidden;
}
.vertical-teaser__content {
    grid-area: vertical-teaser__content;
    padding: calc(20rem/16);
    font-size: calc(12rem/16);
    color: var(--color-text-muted);
    line-height: 1.5;
    @media screen and (min-width:768px){
        padding: calc(20rem/16) calc(30rem/16);
        font-size: calc(16rem/16);
        line-height: calc(22/18);
    }
}
.vertical-teaser__title {
    text-transform: uppercase;
    color: var(--color-dark);
    font-family: var(--font-decorative);
    font-size: calc(24rem/16);
    line-height: 1;
    @media screen and (min-width: 768px){
        margin-bottom: 0;
        font-size: clamp(calc(20rem/16), 2.25vw, calc(30rem/16));
    }
}
.vertical-teaser__additional-infos {
    margin-top: calc(10rem/16);
    margin-bottom: calc(10rem/16);
}
.vertical-teaser__text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.vertical-teaser__text--short {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    @media screen and (min-width: 768px){
        -webkit-line-clamp: 1;
    }
}
.vertical-teaser__top {
    padding: calc(15rem/16) calc(20rem/16);
    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(30rem/16) calc(60rem/16) calc(20rem/16);
    }
}
.vertical-teaser__offer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: calc(1rem/16) solid var(--color-grey);
    padding: calc(15rem/16) calc(20rem/16);
    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(30rem/16) calc(20rem/16) calc(20rem/16);
    }
}
.vertical-teaser__price {
    color: var(--color-blue);
    font-family: var(--font-decorative);
    font-size: clamp(calc(20rem/16), 2.25vw, calc(30rem/16));
}
.vertical-teaser__icon-badge {
    font-size: calc(28rem/16);
    margin-bottom: calc(10rem/16);
    color: var(--color-primary);
}