.multiple-selects-dropdown__button{
    text-align: left;
    font-family: var(--font-default);
    padding: 1.25rem 1.25rem .5rem;
    box-shadow: 0 .625rem 1.25rem 0 rgba(1,25,38,0.08);
}

.multiple-selects-dropdown__label{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
}

.has-value .multiple-selects-dropdown__label{
    left: 1.0625rem;
    top: .9375rem;
    font-size: calc(8rem/16);
}

.multiple-selects-dropdown__values{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px;
    display: block;
}

.multiple-selects-dropdown__icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(270deg);
    right: calc(15rem/16);
    font-size: calc(9rem/16);
}


.multiple-selects-dropdown__menu{
    width: 100%;
    padding: 0;
    border-radius: 0;
    border: 0;
}


.multiple-selects-dropdown__list{
    max-height: calc(215rem/16);
    overflow-y: auto;
}


.multiple-selects-dropdown__submit-btn{
    height: calc(50rem/16);
    width: calc(100% - 0.5rem);

    @media screen and (max-width: 767px){
        font-size: calc(10rem/16);
        letter-spacing: calc(2.5rem/16);
        height: calc(40rem/16);
    }
}
