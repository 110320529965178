.download-list-item {
    background-color: var(--color-light);
    padding: calc(22rem/16) calc(30rem/16);
    color: var(--color-dark);
}
.download-list-item:first-of-type {
    margin-top: 0;
}

.download-list-item__title {
    font-family: var(--font-decorative);
    font-size: calc(26rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
        line-height: calc(39/32);
    }
}
.download-list-item__subtitle, .download-list-item__title {
    text-align: center;

    @media screen and (min-width: 768px) {
        text-align: left;
    }
}

.download-list-item__buttons {
    text-align: right;
}

.download-list-item__subtitle {
    font-family: var(--font-default);
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
        line-height: calc(22/18);
    }
}