.congress-benefits__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.congress-benefits__icon {
    margin-bottom: calc(20rem/16);
    font-size: calc(40rem/16);
}

.congress-benefits__title {
    margin-bottom: calc(16rem/16);
    font-size: calc(20rem/16);
    font-family: var(--font-decorative);
    text-align: center;
    letter-spacing: calc(2rem/16);
    @media screen and (min-width:768px){
        font-size: calc(33rem/16);
    }
}