/*Base-Styling for HTML-Elements*/

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
th {
    font-family: var(--font-default-bold);
    font-weight: normal;
}
textarea {
    resize: vertical;
}

label {
    font-weight: normal;
    margin-bottom: 0;
}
ul,
ol,
dl {
    margin-bottom: 0;
}
dt {
    font-weight: normal;
}

*::-moz-selection {
    color: #ffffff;
    background-color:  var(--color-primary);
}
*::selection {
    color: #ffffff;
    background-color: var(--color-primary);
}
video {
    background: #000000;
}

figure {
    margin: 0;
}





/**
Template name: Banner
URI: https://cookieinformation.com
Version 1.0.0
**/

#Coi-Renew {
    display: none;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 60px;
    background: #fff;
    z-index: 99999999999;
    padding: 10px;
    cursor: pointer;
    border-top-right-radius: 50%;
    transform: scale(.7);
    transform-origin: bottom left;
    transition: all .3s ease;
    border:0 !important;
}
@media screen and (max-width: 767px) {
    #Coi-Renew {
        position:absolute;
        left:0;
        bottom: 105px;
    }
}
#coi-banner-categories{
    display:none;
}
#Coi-Renew:hover {
    transform: scale(1);
}
#Coi-Renew svg {
    width: 100%;
}
#Coi-Renew .renew_path {
    fill: #ff1a1a !important;
}

.coi-consent-banner__consent-area {
    background-color: #fff
}
.coi-consent-banner__summary {
    background-color: #f6f6f6
}
.coi-consent-banner__category-expander:checked ~ .coi-consent-banner__name-container, .coi-consent-banner__cookie-details:nth-child(even), .coi-consent-banner__indicator.active {
    background: #eee
}
.coi-consent-banner__summary-texts, .summary-texts__hide-details, .summary-texts__show-details, .summary-texts__title {
    color: #000
}
.summary-texts__description a {
    color: #000000;
}
.bottom-bar__update-consent, .coi-consent-banner__agree-button {
    background-color: #f33;
    color: #fff;
}
.bottom-bar__update-consent:hover, .coi-consent-banner__agree-button:hover {
    background-color: #d30000;
}
#coiConsentBanner{
    display:none;
}
#coiConsentBanner * {
    box-sizing: border-box;
    text-align: initial;
    line-height: normal;
}
#coiConsentBanner ul,#coiConsentBanner ol{
    padding:0;
    list-style-position: inside;
}
.coi-consent-banner__consent-area {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 30px;
    box-sizing: border-box;
    color: #fff;
}
.bottom-bar__update-consent, .coi-consent-banner__agree-button {
    min-width: 120px;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    letter-spacing: .5px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 10px 0;
    padding: 15px 20px;
    text-align: center !important;
}
@media screen and (max-width: 767px) {
    .bottom-bar__update-consent, .coi-consent-banner__agree-button {
        font-size: 12px;
        padding: 5px;
        min-width: auto;
    }
}
.summary-texts__show-details {
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 16px;
    font-weight: 500;
    cursor: pointer;
    padding: 10px 20px;
}
.coi-consent-banner__decline-button{
    font-size: 14px;
    margin: 10px 0;
    padding:0 !important;
    background:none !important;
    border: none !important;
    cursor: pointer !important;
    align-self: baseline;
}
.coi-consent-banner__summary {
    box-sizing: border-box;
    display: flex;
    padding: 50px 30px 50px 30px;
    line-height: 16px;
    justify-content: space-between
}
.coi-consent-banner__brand {
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: normal;
    margin: 0 0 0 10px
}
.coi-consent-banner__brand a {
    display: block
}
.coi-consent-banner__summary-texts {
    flex: 4
}
.summary-texts__title {
    font-size: 21px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom:10px;
}
.coi-consent-banner__summary-texts {
    display: flex;
    flex-direction: column
}
.coi-consent-banner__no-cookies {
    font-size: 1.8em;
    margin: 20px 0
}
.coi-consent-banner__indicator.active {
    border-bottom: 1px solid #555;
    color: #000
}
.coi-consent-banner__category-expander:checked ~ .coi-consent-banner__name-container {
    color: #f33
}
.coi-consent-banner__slider:after {
    background-color: #d30000
}
.BannerLeft {
    left: 0
}
.BannerLeft {
    right: 0
}
.BannerBottom {
    bottom: 0
}
.coi-consent-banner {
    position: fixed;
    width: 100%;
    max-height: 90%;
    border-top: 1px solid #e0e0e0;
    overflow-y: auto;
    background-color: #fdfdfd;
    z-index: 10000;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, .54);
    box-sizing: border-box;
}
.coi-consent-banner::-webkit-scrollbar {
    width: 0 !important
}
.coi-consent-banner__consent-area {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 30px;
    box-sizing: border-box;
    color: #fff
}
@media screen and (min-width:700px) {
    .coi-consent-banner__base {
        display: inline-flex !important;
        width: 100%
    }
    .coi-consent-banner__consent-area {
        flex-direction: column;
        border-right: 1px solid #e0e0e0
    }
    .coi-consent-banner__consent-area {
        width: 25%
    }
    .coi-consent-banner__summary {
        flex: 3 0 0
    }
}
@media screen and (max-width:700px) {
    .coi-consent-banner__base {
        display: flex;
        flex-direction: column-reverse
    }
    .coi-consent-banner__consent-area {
        flex-direction: row;
        flex: 1;
        width: 100%;
        justify-content: space-evenly
    }
    .coi-consent-banner__summary {
        flex: 1;
        flex-direction: column
    }
    .coi-consent-banner__summary * {
        text-align: center !important
    }
    .coi-consent-banner__brand {
        margin: 10px 0 0 0;
        justify-content: center
    }
}
.summary-texts__hide-details {
    display: none;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 16px;
    font-weight: 500;
    cursor: pointer;
    padding: 10px 20px
}
.coi-consent-banner__bottom {
    max-height: 0;
    transition: max-height .5s;
    border-top: solid 1px #e0e0e0;
    overflow: auto;
    background: #fff
}
.coi-consent-banner__bottom::-webkit-scrollbar {
    width: 0
}
.coi-consent-banner__indicators-container {
    min-height: 54px;
    display: inline-flex;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    width: 25%;
    height: auto
}
.coi-consent-banner__indicator {
    text-align: center !important;
    padding: 20px 30px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #555;
    text-transform: uppercase;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 54px
}
.coi-consent-banner__indicator:hover {
    cursor: pointer;
    border-bottom: 1px solid #555;
    color: #000
}
.coi-consent-banner__bottom-columns {
    width: 100%;
    max-height: 280px
}
.coi-consent-banner__left-column {
    background-color: #fcfcfc;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 25px
}
.coi-consent-banner__categories-wrapper {
    box-sizing: border-box;
    position: relative
}
.coi-consent-banner__category-container {
    display: flex;
    flex-direction: column;
    position: initial
}
.coi-consent-banner__name-container {
    align-items: center;
    position: relative;
    height: 56px;
    line-height: 18px;
    background-color: #fcfcfc;
    display: inline-flex;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    color: #272727
}
.coi-consent-banner__name-container:hover {
    color: #ff1a1a
}
.coi-consent-banner__name-container label {
    display: flex;
    height: -webkit-fill-available;
    align-items: center;
    height: 100%
}
.coi-consent-banner__category-name {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    font-weight: 500;
    line-height: 55px;
    cursor: pointer;
    margin: 0
}
.coi-consent-banner__category-name:last-child {
    border-bottom: none
}
.coi-consent-banner__category-expander {
    position: absolute !important;
    opacity: 0
}
.coi-consent-banner__category-expander:focus + .coi-consent-banner__name-container {
    outline: 1px solid #6495ed
}
.coi-consent-banner__about-owner-column {
    display: none;
    line-height: 20px;
    font-size: 14px
}
.left-column__about-owner {
    font-size: 14px
}
.left-column__last-scan {
    display: flex;
    flex-direction: column;
    padding-top: 35px
}
.left-column__bold-text {
    font-weight: 700
}
.left-column__website_domains {
    word-break: break-word;
    clear: both;
    display: block;
    margin-top: 5px
}
.coi-consent-banner__right-column {
    height: auto;
    flex-direction: column;
    margin-top: -55px
}
.coi-consent-banner__description-container {
    overflow: auto
}
.coi-consent-banner__category-description {
    padding: 15px 30px
}
.cookie-details__detail-container {
    font-size: 13px;
    padding: 0 10px;
    flex: 1;
    word-break: break-word
}
.cookie-details__detail-title {
    font-weight: 700;
    color: #333
}
.coi-consent-banner__found-cookies {
    border-top: 1px solid #e0e0e0
}
.coi-consent-banner__cookie-details {
    color: #555;
    display: flex;
    padding: 10px 20px;
    box-sizing: border-box;
    background-color: #fcfcfc
}
.coi-consent-banner__why-cookies {
    padding: 25px 30px;
    flex-direction: column;
    font-size: 14px
}
.coi-consent-banner__why-cookies + span {
    font-size: 14px !important
}
@media screen and (min-width:760px) {
    .coi-consent-banner__left-column {
        width: 25%;
        border-right: 1px solid #e0e0e0
    }
    .coi-consent-banner__right-column {
        flex: 4 0 0
    }
    .coi-consent-banner__description-container {
        height: auto;
        top: -47px;
        bottom: 0;
        right: 0;
        position: absolute;
        width: 75%;
        background: #fff;
        z-index: 1
    }
    .coi-consent-banner__category-container:nth-child(1) .coi-consent-banner__description-container {
        z-index: 2
    }
    .cookie-details__detail-container.cookie-details__detail-container-provider {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 25%
    }
    .cookie-details__detail-container.cookie-details__detail-container-purpose {
        width: 50%
    }
    .cookie-details__detail-container.cookie-details__detail-container-expiry {
        width: 10%
    }
    .coi-consent-banner__name-container {
        width: 25%;
        border-right: 1px solid #e0e0e0;
        box-sizing: border-box
    }
    .coi-consent-banner__category-expander:checked ~ .coi-consent-banner__description-container {
        z-index: 100
    }
    .coi-consent-banner__cookie-details {
        flex-direction: row
    }
    .cookie-details__detail-title {
        margin-right: 10px
    }
}
@media screen and (max-width:760px) {
    .coi-consent-banner__description-container {
        height: 0;
        width: 100%;
        transition: height .9s
    }
    .coi-consent-banner__indicators-container {
        width: 100% !important
    }
    .coi-consent-banner__indicator {
        padding: 20px 0 !important
    }
    .coi-consent-banner__category-expander:checked ~ .coi-consent-banner__description-container {
        height: auto
    }
    .coi-consent-banner__about-owner-column {
        flex-wrap: wrap-reverse
    }
    .coi-consent-banner__left-column, .coi-consent-banner__right-column {
        width: 100%
    }
    .coi-consent-banner__cookie-details {
        flex-direction: column
    }
    .coi-consent-banner__bottom-bar {
        width: 100% !important;
        border-right: none !important
    }
    .coi-consent-banner__right-column {
        margin-top: 0
    }
}
.coi-consent-banner__switch-container {
    position: absolute;
    right: 10px !important;
    cursor: pointer;
    top: 20px
}
.coi-consent-banner__switch {
    position: relative;
    display: flex;
    width: 34px;
    height: 14px
}
.coi-consent-banner__switch-checkbox {
    opacity: 0 !important;
    z-index: 1;
    width: 100%;
    cursor: pointer
}
.coi-consent-banner__slider {
    width: 34px;
    height: 14px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bdbdbd;
    transition: .4s;
    border-radius: 34px
}
.coi-consent-banner__slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -3px;
    bottom: -3px;
    background-color: #f5f5f5;
    transition: .4s;
    border-radius: 50%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, .12), 0 2px 2px 0 rgba(0, 0, 0, .24)
}
.coi-consent-banner__switch-checkbox:checked + .coi-consent-banner__slider {
    background-color: #cc8282
}
.coi-consent-banner__switch-checkbox:focus + .coi-consent-banner__slider:before {
    border: solid 1px #6495ed
}
.coi-consent-banner__switch-checkbox:checked + .coi-consent-banner__slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background-color: #d30000
}
.coi-consent-banner__bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e0e0e0;
    width: 25%;
    border-right: 1px solid #e0e0e0
}
.coi-brand-logo {
    margin: 0 10px;
    font-size: 9px;
    line-height: 11px;
    color: #333;
    text-decoration: none
}
.coi-brand-logo span {
    display: block;
    color: #000
}
@media screen and (min-width:760px) {
    .coi-consent-banner__bottom-columns {
        max-height: 340px
    }
    .coi-consent-banner__category-container:last-of-type div {
        border-bottom: none
    }
}
@media screen and (max-width:760px) {
    .coi-brand-logo {
        position: initial
    }
    .coi-consent-banner__bottom-columns {
        max-height: none
    }
}
@media screen and (max-width:1300px) {
    .coi-consent-banner__indicators-container {
        display: inline-block;
        max-height: none
    }
    .coi-consent-banner__indicator {
        display: block
    }
}
@media screen and (-ms-high-contrast:active), screen and (-ms-high-contrast:none) {
    .coi-consent-banner__base {
        flex-direction: row !important
    }
    .coi-consent-banner__consent-area {
        border-right: solid 1px #e1e1e1;
        width: 25%;
        flex: none
    }
    .coi-consent-banner__summary {
        text-align: left !important;
        width: 75%;
        display: block
    }
    @media screen and (max-width:700px) {
        .coi-consent-banner__base {
            display: block !important
        }
    }
    .coi-consent-banner__indicator {
        display: block;
        max-width: 50%
    }
    .coi-consent-banner__left-column {
        width: 25%;
        flex: none
    }
}