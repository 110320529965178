.scrolling-area {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 0;
    position: relative;
}

.scrolling-area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 calc(8rem / 16) rgba(0, 0, 0, 0.2);
    background-color: transparent;
    transition: all 120ms ease;
}

.scrolling-area::-webkit-scrollbar {
    display: none;
}

.scrolling-area::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    transition: all 120ms ease;
}

.scrolling-area__item {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100%;
}

.scrolling-area__nav {
    display: flex;
    align-items: center;
    margin-top: calc(6rem / 16);
}

.scrolling-area--style {
    margin: 0 calc(-20rem / 16);
}

.scrolling-area--style .scrolling-area__item {
    width: 80%;
    padding: 0 calc(20rem / 16);
}


@media screen and (max-width: 767px) {
    .scrolling-area--xs {
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
    }
    /* .container .scrolling-area--xs {
         margin-left: calc(-20rem / 16);
         margin-right: calc(-20rem / 16);
         padding-left: calc(20rem / 16);
         padding-right: calc(20rem / 16);
     }*/
}

.scrolling-area--slider, .scrolling-area--move-left {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.scrolling-area--slider::-webkit-scrollbar, .scrolling-area--move-left::-webkit-scrollbar {
    display: none;
}

.scrolling-area--slider, .scrolling-area--move-left {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 0;
    position: relative;
}

.scrolling-area__item.scrolling-area__item--mobile-cut {
    flex-shrink: .1;
}

@media screen and (max-width: 767px) {
    .scrolling-area--slider--xs, .scrolling-area--move-left {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .scrolling-area--slider--xs::-webkit-scrollbar, .scrolling-area--move-left::-webkit-scrollbar {
        display: none;
    }
    .scrolling-area--slider--xs, .scrolling-area--move-left {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 0;
        position: relative;
    }
}

.scrolling-area__item {
    /*width: 100%;*/
    flex-shrink: 0;
    scroll-snap-align: start;
}

.scrolling-area__arrows {
    display: flex;
}

.scrolling-area__arrow {
    background-color: var(--color-primary);
    color: var(--color-beige);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    font-size: calc(9rem / 16);
    transition: all 150ms ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    box-shadow: 0 0.125rem 2rem 0 rgba(0, 0, 0, 0.17);

    @media screen and (min-width: 1200px) {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
        font-size: calc(14rem / 16);
    }

}

.scroll-slider__arrow:focus {
    outline: 0;
}

.scroll-slider__arrow:disabled .scroll-slider__arrow-icon {
    opacity: .5;
}

.scroll-slider__arrow--prev:not(:disabled):hover .scroll-slider__arrow-icon {
    transform: translateY(-50%) scale(1.15) rotate(180deg);
}

.scroll-slider__arrow--next:not(:disabled):hover .scroll-slider__arrow-icon {
    transform: translateY(-50%) scale(1.15);
}

.scroll-slider--image-gallery .scroll-slider__arrow,
.scroll-slider--hotel-teaser .scroll-slider__arrow{
    position: absolute;
    top: 0;
    bottom: 0;
    border: 0;
}

.scroll-slider--arrows-even .scroll-slider__arrow--prev {
    left: 0;
}
.scroll-slider--arrows-even .scroll-slider__arrow--next {
    right: 0;
}
.scroll-slider--hotel-teaser .scroll-slider__arrow--prev {
    background: linear-gradient(270deg, rgba(3,12,23,0) 0%, rgba(3,12,23,0.4) 100%);
    width: calc(50rem / 16);
    @media screen and (min-width: 768px) {
        width: calc(120rem / 16);
        font-size: calc(34rem / 16);
    }
}
.scroll-slider--hotel-teaser .scroll-slider__arrow--next {
    background: linear-gradient(90deg, rgba(3,12,23,0) 0%, rgba(3,12,23,0.4) 100%);
    width: calc(50rem / 16);
    @media screen and (min-width: 768px) {
        width: calc(120rem / 16);
        font-size: calc(34rem / 16);
    }
}

.scroll-slider--image-gallery .scroll-slider__arrow--prev {
    background: linear-gradient(270deg, rgba(3, 12, 23, 0) 0%, rgba(3, 12, 23, 0.6) 80%);
    width: calc(50rem / 16);
    @media screen and (min-width: 768px) {
        width: calc(200rem / 16);
        font-size: calc(34rem / 16);
    }
}

.scroll-slider--image-gallery .scroll-slider__arrow--next {
    background: linear-gradient(90deg, rgba(3, 12, 23, 0) 0%, rgba(3, 12, 23, 0.6) 80%);
    width: calc(50rem / 16);
    @media screen and (min-width: 768px) {
        width: calc(200rem / 16);
        font-size: calc(34rem / 16);
    }
}

.scroll-slider--image-gallery .scroll-slider__arrow-icon,
.scroll-slider--hotel-teaser .scroll-slider__arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 250ms ease-in;
    color: var(--color-light);
}
.scroll-slider--hotel-teaser .scroll-slider__arrow-icon {
    text-shadow: 0 0 calc(20rem/16) #000000;
}

.scroll-slider--image-gallery .scroll-slider__arrow--prev .scroll-slider__arrow-icon,
.scroll-slider--hotel-teaser .scroll-slider__arrow--prev .scroll-slider__arrow-icon {
    left: calc(30rem / 16);
    transform: translateY(-50%) rotate(180deg);
    @media screen and (min-width: 1200px) {
        left: calc(50rem / 16);
    }
}

.scroll-slider--image-gallery .scroll-slider__arrow--next .scroll-slider__arrow-icon,
.scroll-slider--hotel-teaser .scroll-slider__arrow--next .scroll-slider__arrow-icon {
    @media screen and (min-width: 1200px) {
        right: calc(50rem / 16);
    }
}

.scroll-slider__arrow--next {
    right:0;
}

.scroll-slider__arrow--prev {
    left:0;
}


