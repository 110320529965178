.citation__citation {
    font-size: calc(18rem/16);
    color: var(--color-light);

    @media screen and (min-width:768px){
        font-size: clamp(calc(20rem/16), 2.25vw, calc(35rem/16));
    }
}
.citation__author {
    font-size: calc(16rem/16);
    color: var(--color-light);
    font-family: var(--font-default-bold);
    margin-top: calc(24rem/16);
    padding-left: calc(40rem/16);
    position: relative;

    @media screen and (min-width:768px){
        font-size: clamp(calc(18rem/16), 1.75vw, calc(25rem/16));
    }
}
.citation__author:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: calc(3rem/16);
    width: calc(30rem/16);
    background: linear-gradient(224.16deg, #882436 0%, #CC044D 37.21%, #CC0422 100%);
}
