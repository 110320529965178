.custom-modal__container--lg {
    max-width: calc(863rem/16);
}
.custom-modal__container {
    position: relative;
}
.custom-modal__close {
    position: absolute;
    top:0;
    right:0;
    background-color: transparent;
    color:#fff;
    font-size: calc(25rem/16);
    padding: calc(15rem/16);
    z-index: 1;
    border: none;
    box-shadow: none;
}
.custom-modal--light .custom-modal__close {
    color: #000;
}
.custom-modal__container {
    pointer-events: auto;
}

.custom-modal__barrierfree .custom-modal__close {
    color: var(--color-blue);
    font-size: calc(10rem/16);
}
.custom-modal__barrierfree .custom-modal__container--lg {
    max-width: calc(753rem/16);
}