.hero {
    position: relative;
    overflow: hidden;
}

.hero__scroll-hint {
    border-radius: 50%;
    position: fixed;
    left: calc(50% - 25px);
    top: calc(100vh - 75px);
    background: var(--color-primary-gradient);
    width: calc(50rem /16);
    height: calc(50rem /16);
    align-items: center;
    justify-content: center;
    font-size: calc(20rem /16);
    animation: jump 2.5s ease;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    cursor: pointer;
    display: flex;
    @media screen and (max-width: 767px){
        display: none;
    }
}
.hero__scroll-hint.is-hidden {
    opacity: 0;
    display: none;
}
@keyframes jump {
    0% {
        transform: translateY(0); }
    10% {
        transform: translateY(-30px);
    }
    20% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(-25px);
    }
    40% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(0);
    }
    70% {
        transform: translateY(-15px);
    }
    80% {
        transform: translateY(0);
    }
    90% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.hero__content {
    @media screen and (max-width: 767px) {
        margin-bottom: calc(32rem / 16);
    }
    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) 0 calc(40rem / 16);
        max-width: calc(730rem / 16);
    }
    @media screen and (min-width: 1200px) {
        padding: calc(100rem / 16) 0 calc(60rem / 16);
    }
}

.hero__breadcrumb {
    margin-top: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-bottom: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
    }
}

.hero__sub-title {
    margin-bottom: calc(22rem / 16);
}

.hero__wysiwyg {
    margin-bottom: calc(30rem / 16);
}

.hero__copyright {
    display: none;

    @media screen and (min-width: 768px) {
        right: .75rem;
        position: absolute;
        bottom: .25rem;
        top: unset !important;
        left: unset !important;
        display: inline;
        width: unset !important;
        height: unset !important;
        text-shadow: calc(2rem / 16) calc(1rem / 16) calc(3rem / 16) #000;
        font-size: clamp(calc(10rem / 16), 1.25vw, calc(14rem / 16));
    }

}

.hero__info-wrapper {
    margin-top: calc(16rem / 16);
    margin-bottom: calc(30rem / 16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(50rem / 16);
        max-width: calc(550rem / 16);
    }
}

.hero__info {
    display: grid;
    grid-template-columns: calc(20rem / 16) 1fr;
    gap: 0 calc(10rem / 16);
    align-items: center;
    font-size: calc(14rem / 16);
    line-height: 1.3;
    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        grid-template-columns: calc(30rem / 16) 1fr;
    }
}

.hero__info-icon {
    font-size: calc(16rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}

.hero__trust-you {
    bottom: .25rem;
    left: 1.25rem;
    z-index: 3;
    position: absolute;
    height: fit-content;
    background-color: white;
    padding: .5rem;
    border-radius: .5rem;

    @media screen and (min-width: 768px) {
        left: unset;
        right: calc(10rem / 16);
        top: calc(14rem / 16);
    }
}

.hero__icon-list-item {
    display: inline-flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    flex-direction: column;
    border: 1px solid #fff;
    color: #fff;
    padding: calc(5rem / 16);
    border-radius: calc(5rem / 16);
    min-width: calc(50rem / 16);
    margin-right: calc(5rem / 16);
    margin-bottom: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        margin-right: calc(10rem / 16);
        margin-bottom: calc(10rem / 16);
        min-width: calc(60rem / 16);
    }
}
.hero__icon-list-item--link {
    background-color: #fff;
    color: var(--color-primary);
    border-color: var(--color-primary);
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
        background-color: var(--color-primary);
        color: #fff;
        border-color: #fff;
    }
}
.hero__icon-list-item-text {
    font-size: calc(10rem / 16);
    text-transform: uppercase;
    letter-spacing: calc(1rem / 16);
    font-family: var(--font-decorative);

}

.hero__icon-list-item-icon {
    font-size: calc(18rem / 16);
    margin-bottom: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }
}

.hero-package__attribute {
    font-size: calc(12rem / 16);
    display: flex;
    align-items: center;
    line-height: 1;
    @media screen and (min-width: 768px) {
        font-size: clamp(calc(12rem / 16), 1.25vw, calc(16rem / 16));
    }
}

.hero-package__attribute + .hero-package__attribute {
    margin-top: calc(16rem / 16);
}

.hero-package__icon {
    font-size: calc(18rem / 16);
    margin-right: calc(6rem / 16);
    margin-bottom: calc(3rem / 16);
    @media screen and (min-width: 768px) {
        font-size: clamp(calc(18rem / 16), 1.25vw, calc(25rem / 16));
        margin-right: calc(12rem / 16);
    }

}

.hero-package__price {
    font-family: var(--font-decorative);
    @media screen and (min-width: 768px) {
        font-size: clamp(calc(24rem / 16), 2vw, calc(36rem / 16));
    }
}

.hero-package__offer {
    font-size: calc(12rem / 16);
    margin-top: calc(20rem / 16);
    @media screen and (min-width: 768px) {
        font-size: clamp(calc(12rem / 16), 1.25vw, calc(16rem / 16));
        margin-top: clamp(calc(24rem / 16), 2vw, calc(36rem / 16));
    }
}

.hero-package__date__dash {
    margin: 0 calc(3rem / 16);
}

.hero-package__offer__btn {
    margin-top: 1rem;
}

.hero-package__services {
    margin-top: calc(20rem / 16);
    @media screen and (min-width: 768px) {
        margin-top: clamp(calc(28rem / 16), 2.5vw, calc(42rem / 16));
    }
}

.hero__icon-genusshauptstadt {
    font-size: calc(32rem / 16);
    margin-bottom: calc(12rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(48rem / 16);
        display: block;
    }
}