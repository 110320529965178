@mixin hyphenation($min-chars:6, $limit-before:3, $limit-after:3) {
    hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    -webkit-hyphenate-limit-before: $limit-before; /* needed for safari */
    -webkit-hyphenate-limit-after: $limit-after; /* needed for safari */
    -moz-hyphenate-limit-chars: $min-chars $limit-before $limit-after;
    -webkit-hyphenate-limit-chars: $min-chars $limit-before $limit-after;
    -ms-hyphenate-limit-chars: $min-chars $limit-before $limit-after;
    hyphenate-limit-chars: $min-chars $limit-before $limit-after;
}