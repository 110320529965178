html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    background-color: var(--color-dark);
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.body--freezed {
    overflow: hidden;
}
/* needed because default gutter is too big for smaller screens */
@media screen and (max-width: 1199px) {
    .row:not([class*=" gx-"]):not([class*=" row-gutter--"]) {
        --bs-gutter-x: calc(20rem/16);
    }
    .gy-grid {
        --bs-gutter-y: calc(20rem/16);
    }
}
