.photo-wall {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: calc(30rem/16) 0;
    align-items: flex-start;
    grid-template-areas:
    "photo-wall__left"
    "photo-wall__right";
    @media screen and (min-width: 768px){
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0 calc(60rem/16);
        grid-template-areas:
        "photo-wall__left photo-wall__right";
    }
}

.photo-wall__left{
    grid-area: photo-wall__left;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: calc(30rem/16) 0;
    grid-template-areas:
    "photo-wall__left--top"
    "photo-wall__left--bottom";
    @media screen and(min-width: 768px){
        padding-bottom: calc(60rem/16);
        gap: calc(60rem/16) 0;
    }
}
.photo-wall__left--top { grid-area: photo-wall__left--top; }
.photo-wall__left--bottom { grid-area: photo-wall__left--bottom; }

.photo-wall__right {
    grid-area: photo-wall__right;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: calc(30rem/16) 0;
    grid-template-areas:
    "photo-wall__right--bottom"
    "photo-wall__right--top"
    ;

    @media screen and(min-width: 768px){
        padding-top: calc(60rem/16);
        gap: calc(60rem/16) 0;
        grid-template-areas:
    "photo-wall__right--top"
    "photo-wall__right--bottom";
    }
}
.photo-wall__right--top { grid-area: photo-wall__right--top; }
.photo-wall__right--bottom { grid-area: photo-wall__right--bottom; }