.form-control{
    padding: 1rem 1rem .5rem ;
    color: var(--color-light);
    border: 1px solid var(--color-light);
    height: calc(50rem/16);
    cursor: pointer;
    border-radius: 0!important;
    background-color: var(--color-dark);
    font-size: calc(14rem/16);
}
.form-control--large {
    @media screen and (min-width: 768px) {
        height: calc(80rem/16);
        padding: calc(30rem/16) calc(20rem/16) calc(20rem/16);
    }
}
.form-control:focus, .form-control:disabled {
    background-color: var(--color-dark);
    color: var(--color-light);
    border: 1px solid;
    border-color:var(--color-light);
    outline: none;
}
.form-control[readonly]:focus{
    outline: none;
    box-shadow: none;
}

.form-control.disable{
    pointer-events: none;
}

textarea.form-control{
    height: auto;
}


.has-error .form-control{
    border: 1px solid var(--color-danger);
}

.select {
    position: relative;
}
.select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:before,
.search-input:before,
.datepicker:before {
    font-family: "iconfont";
    color: var(--color-light);
    speak: none;
    position: absolute;
    pointer-events: none;
    top: 50%;
    right: calc(20rem / 16);
    z-index: 2;
    line-height: 1;
}
.select:before {
    content: var(--icon-arrow-right);
    font-size: calc(12rem/16);
    transform: translateY(-50%) rotate(90deg);
}
.select__input--light {
    min-width: calc(105rem/16);
}
.select__input--light select:focus,
.select__input--light select{
    background-color: #fff;
    border: calc(1rem/16) solid var(--color-blue);
}
.select__input--light select option{
    background-color: #fff;
    color: var(--color-blue);
}
.select__input--light:before {
    color: var(--color-primary)!important;
}
.select__input--light label {
    color: var(--color-blue);
}
.select__input--light .form-control,
.select__input--light .form-control:focus {
    color: var(--color-blue);
}

.search-input:before {
    content: var(--icon-search);
    transform: translateY(-50%);
    font-size: calc(20rem/16);
}
.datepicker {
    position: relative;
}
.datepicker:before {
    content: var(--icon-calendar);
    transform: translateY(-50%);
    font-size: calc(14rem/16);
    right: calc(10rem/16);
}
.datepicker--disabled {
    opacity: 0.5;
}
.search-input .form-control{
    padding: .5rem 1rem;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: var(--color-light);
}
::-moz-placeholder { /* Firefox 19+ */
    color: var(--color-light);
}
:-ms-input-placeholder { /* IE 10+ */
    color: var(--color-light);
}
:-moz-placeholder { /* Firefox 18- */
    color: var(--color-light);
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 3rem var(--color-dark) inset!important;
    -webkit-text-fill-color: var(--color-light)!important;
    background-image: none !important;
}