.breadcrumb {
    list-style: none;
    position: relative;
    padding-top: calc(9rem/16);
    padding-bottom: calc(20rem/16);
    display: flex;
    @media screen and (max-width: 767px) {
        display: inline-block;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        padding-right: calc(10rem/16);
    }
    @media screen and (min-width: 768px) {
        padding-bottom: calc(50rem/16);
    }
}

.breadcrumb-item {
    color: var(--color-text-muted);
    font-family: var(--font-default);
    font-size: calc(12rem/16);
    padding: 0 calc(6rem/16);
    position: relative;

    @media screen and (max-width: 767px) {
        display: inline;
    }
    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
        padding: 0 calc(8rem/16);
    }
    &:hover {
        color: var(--color-light);
    }
}
.breadcrumb-item:after {
    content: '';
    width: calc(1rem/16);
    height: 60%;
    position: absolute;
    background: var(--color-text-muted);
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}
.breadcrumb-item:last-of-type:after {
    background:transparent;
}

.breadcrumb-item:first-of-type {
    padding-left: 0;
}

.breadcrumb-item.active {
    color: var(--color-light);
}
