:root {
    --mega-nav-level-0-item-spacing: calc(16rem / 16);
}

@media (hover: none) {
    .mega-nav__item-content--level-0 {
        display: flex;
    }
}

@media screen and (min-width: 768px) {
    .mega-nav--level-0 {
        display: flex;
        flex: auto;
        align-items: center;
        height: 100%;
        width: 27%;
    }
    .mega-nav__list--level-0 {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-right: calc(1rem/16) solid var(--color-middle-blue);
        margin-left: calc(var(--mega-nav-level-0-item-spacing) * -1);
        margin-right: calc(var(--mega-nav-level-0-item-spacing) * -1);

        height: 100%;
        max-height: calc(100vh - calc(300rem/16));
        overflow-y: auto;
    }

    .mega-nav__item--level-0 {
        display: flex;
        flex-direction: column;
        flex: auto;
        position: static;
    }

    .mega-nav__item--level-0.is-open > .mega-nav__item-content--link {
        color: var(--color-text-default);
    }

    .mega-nav__item--level-0 > .mega-nav__item-content--link {
        color: var(--color-text-muted);
    }

    .mega-nav__item-content--level-0 {
        flex-direction: column;
        flex: auto;
        text-align:left;
        padding: 1.5rem var(--mega-nav-level-0-item-spacing);
    }

    .mega-nav__item.is-active > .mega-nav__item-content--level-0 {
        color: var(--color-text-default);
        & .mega-nav__item-content__text {
            overflow: hidden;
            background: linear-gradient(224.43deg, #882436 0%, #CC044D 30.62%, #CC0422 100%) repeat-x 0 100%;
            background-size: calc(2rem / 16) calc(2rem / 16);
            text-decoration: none;
        }
    }
}
