.reset-filters {
    display: flex;
    margin-bottom: calc(25rem/16);
    flex-wrap: wrap;
}
.reset-filters__text {
    font-size: calc(14rem/16);
    margin-bottom: calc(5rem/16);
    margin-right: calc(12rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
    }
}
.reset-filter {
    background-color: rgba(70,78,89,0.5);
    color:#fff;
    font-size: calc(12rem/16);
    border: none;
    box-shadow: none;
    padding: calc(2rem/16) calc(4rem/16);
    margin-right: calc(12rem/16);
    margin-bottom: calc(12rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(2rem/16) calc(8rem/16);
        font-size: calc(14rem/16);
    }
}
.reset-filter__icon {
    font-size: calc(12rem/16);
    margin-right: calc(8rem/16);
}
.reset-filter--spacer{
    margin-top: 1rem;
    margin-bottom: 0;
}