.main-navbar-meta {
    height: calc(56rem/16);
    display: flex;
    padding-left: calc(30rem/16);
    padding-right: calc(30rem/16);
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-middle-blue);

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.main-navbar-meta__link-list {
    display: flex;
    margin-left: calc(-20rem/16);
    margin-right: calc(-20rem/16);
}

.main-navbar__link-list-item {
    padding-left: calc(10rem/16);
    padding-right: calc(10rem/16);
}

.main-navbar-meta__link {
    color: var(--color-text-muted);
    text-transform: uppercase;
    font-size: calc(20rem/16);
    letter-spacing: calc(1rem/16);
    font-family: var(--font-decorative);

    &.is-active {
        color: var(--color-text-default);
        position: relative;

        &:before {
            content:'';
            position: absolute;
            bottom:0;
            height: calc(1rem/16);
            left:0;
            width: 100%;
            background: var(--color-primary-gradient);
        }
    }
}

@media screen and (max-width: 767px){
    .main-navbar__btn-link.is-active {
        position: relative;
    }
    .main-navbar__btn-link.is-active:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        height: calc(2rem /16);
        background: var(--color-primary-gradient);
    }
}


.main-navbar-dropdown {
    background-color: var(--color-blue-muted);
    padding: calc(20rem /16);
    margin: 2rem -1.875rem;
}
.main-navbar-dropdown__button,
.main-navbar-dropdown__button:focus,
.main-navbar-dropdown__button:active {
    text-align: left;
    position: relative;
    background: var(--color-blue-muted);
    font-family: var(--font-default);
    padding: .75rem 1.25rem;
    box-shadow: 0 .625rem 1.25rem 0 rgba(1,25,38,0.08);
    max-width: 50vw;
    margin: 0 auto;
}
.main-navbar-dropdown__title {
    font-family: var(--font-decorative);
    font-size: calc(20rem/16);
    text-align: center;
    margin-bottom: calc(8rem /16);
}
.main-navbar-dropdown__label {
    text-transform: uppercase;
}
.main-navbar-dropdown__icon{
    position: absolute;
    top: 50%;
    color: #fff;
    transform: translateY(-50%) rotate(270deg);
    right: calc(15rem/16);
    font-size: calc(9rem/16);
}

.main-navbar-dropdown__list {
    width: 50vw;
    border-radius: 0;
    background-color: var(--color-blue-muted);
    color: #fff;
}
.main-navbar-dropdown__list-item {
    padding: .75rem 1.25rem;
}
.main-navbar-dropdown__link {
    color: #fff;
    text-transform: uppercase;
    font-size: calc(20rem/16);
    letter-spacing: calc(1rem/16);
    font-family: var(--font-decorative);
}