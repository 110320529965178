.step-form {
    padding-top: calc(20rem/16);
    @media screen and (min-width: 768px) {
        padding-top: calc(25rem/16);
    }
}

.step-form__wrapper {
    padding: calc(80rem/16) calc(20rem/16) calc(40rem/16);
    position: relative;
    border: 1px solid var(--color-text-muted);
    @media screen and (min-width: 768px) {
        padding-bottom: calc(80rem/16);
        padding-top: calc(100rem/16);
    }
}

.step-form__legend {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(20rem/-16);
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    hyphens: auto;
    @media screen and (min-width: 768px) {
        width: unset;
        top: calc(25rem/-16);
    }
}

.step-form__legend-item__counter {
    height: calc(40rem/16);
    width: calc(40rem/16);
    border: calc(1rem/16) solid var(--color-text-muted);;
    color: var(--color-text-muted);
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: calc(20rem/16);
    text-align:center;
    background-color: var(--color-dark);
    letter-spacing: calc(1.33rem/16);
    border-radius: 50%;
    transition: transform 0.3s ease;
    font-family: var(--font-decorative);
    position: relative;
    @media screen and (min-width: 768px){
        height: calc(50rem/16);
        width: calc(50rem/16);
        font-size: calc(30rem/16);
    }
}
.step-form__legend-item.is-current .step-form__legend-item__counter {
    border-color: var(--color-light);
    background-color: var(--color-light);
    color: var(--color-primary);
}

.step-form__legend-item__title {
    font-size: calc(16rem/16);
    text-align: center;
    color: var(--color-text-muted);
    margin-top: calc(16rem/16);
    line-height: 1;
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.step-form__legend-item.is-current .step-form__legend-item__title {
    color: var(--color-light);
}
.step-form__legend-item {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 768px) {
        width: calc(150rem/16);
    }
}
.step-form__legend-item:not(:last-child){
    margin-right: calc(20rem/16);
    @media screen and (min-width: 768px) {
        margin-right: calc(100rem/16);
    }
}
.step-form__legend-item__counter-wrapper {
    display:grid;
    grid-template-columns: auto 1fr;
    justify-content: center;
    align-items:center;
}


.step-form__legend.step-form__columns--group_request {
    grid-template-columns: calc(50rem/16) 1fr calc(50rem/16) 1fr calc(50rem/16);
}

.step-form__step.is-active {
    display: block;
}
.step-form__step {
    display: none;
}

.is-checked .step-form__legend-item__checked {
    display: flex;
}

.step-form__legend-item__checked {
    height: calc(16rem/16);
    width: calc(16rem/16);
    border-radius: 50%;
    background-color: var(--color-text-muted);
    color: #fff;
    font-size: calc(6rem/16);
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(24rem/16);
    right: 0;
    @media screen and (min-width:768px){
        top: calc(30rem/16);
        height: calc(20rem/16);
        width: calc(20rem/16);
        font-size: calc(8rem/16);
    }

}

.step-form__separator {
    height: calc(1rem/16);
    background-color: var(--color-text-muted);
}

.step-form__booking-item{
    display: grid;
    align-items: center;
    grid-template-columns: 1.4fr 0.5fr;
    grid-template-rows: auto 1fr;
    gap: calc(10rem/16) calc(8rem/16);
    grid-template-areas:
    ". ."
    "select select";
    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 1.3fr 0.4fr 1.1fr;
        grid-template-rows: 1fr;
        gap: 0px calc(16rem/16);
        grid-template-areas:
    ". . select";
    }
}

.step-form__booking-item+.step-form__booking-item {
    margin-top: 1.25rem;
}

.step-form__booking-item__select {
    grid-area: select;
}
.step-form__price{
    font-family: var(--font-decorative);
    font-size: calc(20rem/16);
    line-height: 1;
    @media screen and (min-width: 768px) {
        font-size: calc(25rem/16);
    }
}
.step-form__booking-item__description{
    font-size: calc(9rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(11rem/16);
        margin-top: calc(6rem/16);
    }
}
.step-form-confirmation__icon {
    background-color: var(--color-light);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(40rem/16);
    min-width: calc(40rem/16);
    border-radius: 50%;
    margin-right: calc(8rem/16);
}