.img-teaser-big {
    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: 0 0;
    grid-template-areas:
    "img-teaser-big__image"
    "img-teaser-big__content";

    @media screen and(min-width:768px){
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0 0;
        grid-template-areas:
    "img-teaser-big__image img-teaser-big__content";
    }
}
.img-teaser-big.img-teaser-big--img-right{
    @media screen and(min-width:768px){
        grid-template-areas:
    "img-teaser-big__content img-teaser-big__image";
    }
}
.img-teaser-big__image {
    grid-area: img-teaser-big__image;
    position: relative;
}
.img-teaser-big__content {
    grid-area: img-teaser-big__content;
    background-color: #fff;
    padding: calc(20rem/16) calc(20rem/16) 0;
    z-index: 0;

    @media screen and(min-width:768px){
        margin-bottom: calc(12rem/16);
        margin-top: calc(20rem/16);
        padding: calc(20rem/16) calc(20rem/16) calc(12rem/16) calc(20rem/16);
    }
    @media screen and(min-width:1200px){
        margin-bottom: calc(36rem/16);
        margin-top: calc(54rem/16);
        padding: calc(40rem/16) calc(40rem/16) calc(12rem/16) calc(40rem/16);
    }
    @media screen and(min-width:1500px){
        margin-bottom: calc(36rem/16);
        margin-top: calc(54rem/16);
        padding: calc(60rem/16) calc(60rem/16) calc(12rem/16) calc(60rem/16);
    }
}
.img-teaser-big__content .wysiwyg > p {
    margin-bottom: calc(5rem/16);
}
.img-teaser-big__content .wysiwyg ul li {
    line-height: 1.8;
}
.img-teaser-big__content .wysiwyg ul li:before {
    top: calc(9rem/16);
    @media screen and (min-width: 768px) {
        top: calc(9rem/16);
    }
}
.img-teaser-big__copyright{
    display: none;
}
@media screen and(min-width:768px){
    .img-teaser-big__copyright {
        display: block;
        position: absolute;
        bottom: .25rem;
        left: .75rem;
        font-size: clamp(calc(12rem/16), 1.25vw, calc(14rem/16));
        text-shadow:calc(2rem/16) calc(1rem/16) calc(3rem/16) #000;
    }
    .img-teaser-big--img-right .img-teaser-big__copyright {
        right: .75rem;
        left: unset;
    }
}