.info-box {
    position: relative;
    overflow:visible !important;
}
.info-box:after {
    content:'';
    background-image: url("img/pois/poi-close.svg");
    background-size: contain;
    position: absolute;
    bottom: -23px;
    z-index: 999;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%);
    background-repeat: no-repeat;
    width: calc(48rem/16);
    height: calc(60rem/16);
    pointer-events: none;
}
.info-box__body {
    color: var(--color-default-dark);
    background-color:#fff;
    padding: calc(15rem/16) calc(15rem/16) calc(40rem/16);
    @media screen and (min-width:768px) {
        padding: calc(15rem/16) calc(30rem/16) calc(40rem/16);
    }
}
.info-box__title {
    font-family: var(--font-decorative);
    font-size: calc(20rem/16);
    line-height: calc(22/20);
    @media screen and (min-width:768px) {
        font-size: calc(24rem/16);
        line-height: calc(32/24);
    }
}
.info-box__subtitle {
    font-size: calc(14rem/16);
    line-height: calc(18/16);
    @media screen and (min-width:768px) {
        font-size: 1rem;
        line-height: calc(32/16);
    }
}
.info-box > img {
    width: calc(30rem/16);
    height: calc(30rem/16);
    z-index: 9;
    background: #fff;
    padding: 8px;
}
.info-box .link-underlined {
    z-index: 2;
    font-size: 1rem;
    margin-top: calc(10rem/16);
}