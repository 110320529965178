.custom-checkbox {
    position: relative;
    padding-left: 1.7em;
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: 1.25em;
    height: 1.25em;
    margin-top: 0.12em;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.5em;
    color: var(--color-primary);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
    font-size: calc(14rem/16);
    margin-top: calc(2rem/16);
}
.custom-checkbox__text a {
    border: none;
    position: relative;
    box-shadow: none;
    overflow: hidden;
    padding: calc(3rem/16) 0;
    background: linear-gradient(224.43deg, #882436 0%, #CC044D 30.62%, #CC0422 100%) repeat-x 0 100%;
    background-size: calc(2rem / 16) calc(2rem / 16);
    text-decoration: none;
    transition: background-size .2s ease-in;
    cursor: pointer;

    &:hover {
        background-size: calc(2rem / 16) 100%;
    }
}
.custom-checkbox-filter {
    padding-left:0;
}
.custom-checkbox-filter__text {
    font-size: calc(14rem/16);
    letter-spacing: calc(1rem/16);
    font-family: var(--font-decorative);
    text-transform: uppercase;
    background-color:#fff;
    color: var(--color-text-muted);
    border-bottom: 2px solid rgba(95,105,118,0.1);
    height: auto;
    display: flex;
    justify-content: flex-start;
    padding: calc(10rem/16) calc(10rem/16);
    align-items:center;
    text-align:left;
    @media screen and (min-width: 1200px) {
        padding: calc(10rem/16) calc(30rem/16);
        font-size: calc(20rem/16);
        height: calc(77rem/16);
    }
}
.custom-checkbox-filter__icon {
    font-size: calc(17rem/16);
    width: calc(20rem/16);
    margin-right: calc(5rem/16);
    @media screen and (min-width: 1200px) {
        font-size: calc(30rem/16);
        width: calc(35rem/16);
        margin-right: calc(20rem/16);
    }
}
.custom-checkbox-filter__box {
    position: relative;
    display: flex;
    margin-left: auto;
}
.custom-checkbox-filter__input:checked ~ .custom-checkbox-filter__text{
    background-color: rgba(95,105,118,0.1);
    color: var(--color-default-dark);
    border-bottom: 2px solid #fff;
}

.custom-checkbox-filter__input:checked ~ .custom-checkbox-filter__text .custom-checkbox-filter__box:before {
    visibility: visible;
}


/* inline */
.form-check-inline .form-check-input {
    position: absolute; /* override bootstrap */
}