.accordion-packages .accordion__body {
    @media screen and (min-width: 768px){
        padding: calc(42rem/16);
    }
}
.accordion-packages__attribute {
    font-size: calc(12rem / 16);
    display: flex;
    align-items: center;
    line-height: 1;
    @media screen and (min-width: 768px) {
        font-size: clamp(calc(12rem / 16), 1.25vw, calc(16rem / 16));
    }
}

.accordion-packages__attribute + .accordion-packages__attribute {
    margin-top: calc(16rem / 16);
}

.accordion-packages__icon {
    font-size: calc(18rem / 16);
    margin-right: calc(6rem / 16);
    margin-bottom: calc(3rem / 16);
    @media screen and (min-width: 768px) {
        font-size: clamp(calc(18rem / 16), 1.25vw, calc(25rem / 16));
        margin-right: calc(12rem / 16);
    }

}