.btn{
    border-radius: 0;
    border: none;
    padding: 1rem calc(15rem/16);
    font-size: calc(14rem/16);
    line-height: 1;
    @media screen and (min-width: 768px) {
        padding: 1rem calc(30rem/16);
        font-size: calc(16rem/16);
    }
}
.btn-block {
    width: 100%;
}
.cookie-bar__accept,
.btn-primary-gradient {
    background: linear-gradient(224.43deg, #CC0422 0%, #CC044D 30.62%, #882436 50%, #CC044D 69.38%, #CC0422 100%);
    background-size: 200% auto;
    text-align: center;
    font-family: var(--font-default);
    color: #fff;
    transition: .5s;
    line-height: 1;

    &:hover{
        background-position: 100%;
        color: var(--color-light);
    }
}

.btn-primary-gradient--large {
    background: linear-gradient(224.43deg, #CC0422 0%, #CC044D 30.62%, #882436 50%, #CC044D 69.38%, #CC0422 100%);
    background-size: 200% auto;
    padding: 2rem 2rem 1rem 1rem;
    text-align: left;
    font-family: var(--font-decorative);
    color: #fff;
    transition: .5s;
    line-height: 1;
    font-size: calc(25rem/16);
    @include hyphenation(6, 3, 3);

    @media screen and (min-width: 768px){
        padding: calc(60rem/16) calc(95rem/16) calc(22rem/16) calc(30rem/16);
        font-size: clamp(calc(20rem/16), 1.75vw, calc(32rem/16));
    }

    &:hover{
        background-position: 100%;
        color: var(--color-light);
    }
}
.btn-back__wrapper {
    padding: calc(20rem/16) calc(30rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(10rem/16) calc(30rem/16) calc(64rem/16) calc(30rem/16);
    }
}
.btn-back {
    font-size: calc(14rem/16);
}
.btn-back__icon {
    color: var(--color-primary-light);
    font-size: calc(12rem/16);
    margin-right: calc(5rem/16);
}
.btn-sm {
    padding: calc(5rem/16);
    font-size: calc(12rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(5rem/16);
        font-size: calc(12rem/16);
    }
}
.btn-outline-primary {
    border-color: var(--color-primary);
    border: 1px solid var(--color-primary);
}