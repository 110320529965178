html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default-xs);
    word-break: break-word;
    @media screen and (min-width: 768px) {
        font-size: var(--font-size-default);
    }
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
}

h1, .h1 {
    font-size: calc(30rem/16);
    font-family: var(--font-decorative);
    @media screen and (min-width: 768px){
        font-size: clamp(calc(30rem/16), 3.5vw, calc(60rem/16));
        letter-spacing: calc(3rem/16);
    }
}
h2, .h2 {
    font-size: calc(25rem/16);
    font-family: var(--font-decorative);
    @media screen and (min-width: 768px){
        font-size: clamp(calc(20rem/16), 2.25vw, calc(40rem/16));
        letter-spacing: calc(1rem/16);
    }
}
h3, .h3 {
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    line-height: 1.5;
    @media screen and (min-width: 768px){
        line-height: 1.5;
        font-size: calc(24rem/16);
        letter-spacing: calc(1rem/16);
    }
}
h4, .h4 {
    font-size: calc(14rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}
h5, .h5 {
    font-size: calc(14rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}
h6, .h6 {
    font-size: calc(12rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

table{
    word-break: initial;
}