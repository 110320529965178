footer {
    z-index: 1;
    display: block;
    position: relative;
}
.footer-top__wrapper{
    background-color: var(--color-blue);
    position: relative;
}
.footer-top__wrapper:before{
    @media screen and (min-width: 768px){
        content: "";
        background-color: var(--color-dark);
        height: calc(100rem/16);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
    }
}
.footer-top {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "footer-top__left"
        "footer-top__right";

    @media screen and(min-width: 768px){
        grid-template-columns: 44% 56%;
        grid-template-rows: 1fr;
        gap: 0 0;
        grid-template-areas: "footer-top__left footer-top__right";
    }
    @media screen and (min-width: 1921px){
        margin: 0 auto;
        max-width: calc(1920rem/16);
    }
}
.footer-top__left{
    grid-area: footer-top__left;
    position: relative;
}
.footer-top__left__content {
    padding: calc(30rem/16);
    @media screen and (min-width: 768px){
        padding: 0;
        position: absolute;
        left: 40%;
        top: calc(50% + calc(50rem/16));
        transform: translate(-50%, -50%);
    }
}
.footer-top__info-item + .footer-top__info-item {
    margin-top: 1.25rem;
    @media screen and (min-width: 768px){
        margin-top: 2rem;
    }
}
.footer-top__info-item__title {
    font-size: calc(12rem/16);
    color: var(--color-text-muted);

    @media screen and (min-width: 768px){
        font-size: clamp(12px, 1.25vw, 20px);
    }
}
.footer-top__social-icon{
    font-size: calc(20rem/16);
    color: #fff;
    margin-top: .375rem;
    margin-right: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: 2rem;
        margin-right: calc(30rem/16);
    }
}
.footer-top__right{
    grid-area: footer-top__right;
    position: relative;
    padding-left: calc(30rem/16);
    @media screen and (min-width: 768px){
        padding-left: 0;
    }
}
.footer-top__copyright{
    position: absolute;
    top: .25rem;
    right: .75rem;
    font-size: calc(10rem/16);
    text-shadow: 0 0 calc(5rem/16) rgba(0,0,0,0.8);
}
.footer-top__right__link{
    position: absolute;
    bottom: 0;
    left: 0;

    @media screen and (min-width: 768px){
        left: calc(30rem/-16);
    }
}
.footer-bottom__wrapper{
    @media screen and (min-width: 768px){
        margin: 0 auto;
        padding: 0 calc(30rem/16);
    }
    @media screen and (min-width: 1921px){

        max-width: calc(1920rem/16);
    }
}
.footer-bottom {
    padding: calc(25rem/16);
    background: var(--color-dark);

    @media screen and (min-width: 768px){
        padding: calc(25rem/16) 0;
    }
}
.footer-bottom__list{
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 767px){
        justify-content: center;
    }
}
.footer-bottom__list li {
    color: var(--color-text-muted);
    transition: .25s;
    font-size: calc(12rem/16);

    @media screen and (min-width: 786px){
        font-size: clamp(12px, 1vw, 18px);
    }

    &:hover{
        color: #fff;
    }
}
.footer-bottom__list li + li {

    @media screen and (min-width: 786px){
        padding-left: 1.5rem;
    }
}
.footer-bottom__list li {

    @media screen and (max-width: 767px){
        padding: .5rem;
    }
}
.footer-bottom__elements {
    height: 1rem;
    @media screen and (min-width: 768px){
        height: clamp(calc(16rem/16), 1.25vw, calc(24rem/16));
    }
}