.accordion .card {
    background-color: #fff;
    border: none;
    margin-bottom: calc(20rem / 16);
    border-radius: 0;
    box-shadow: 0 1.25rem 3.125rem 0 rgba(1, 25, 38, 0.1);
}

.accordion__header-link,
.collapse__header-link {
    width: 100%;
    font-family: var(--font-default-bold);
    font-size: calc(14rem / 16);
    padding: .75rem;
    border: none;
    text-align: left;
    color: inherit;
    cursor: pointer;

    @media screen and (min-width: 768px) {
        padding: .75rem 1.25rem;
        font-size: calc(18rem / 16);
    }
}

.accordion__header-link {
    color: var(--color-light);
    background-color: var(--color-blue);
}

.collapse__header-link {
    background-color: var(--color-light);
    color: var(--color-blue);
}

.accordion__header-link:not(.collapsed) {
    background-color: var(--color-light);
    color: var(--color-blue);
}

.collapse__header-link:not(.collapsed) {
    color: var(--color-light);
    background-color: var(--color-blue);
}

.accordion__icon {
    font-size: calc(16rem / 16);
    transition: transform 200ms ease-in;
    transform: rotate(0);
    color: var(--color-primary);
}

.accordion .accordion__header-link:not(.collapsed) .icon,
.collapse__header-link:not(.collapsed) .icon {
    transform: rotate(45deg);
}

.accordion .card-header {
    border: 0;
}

.accordion__body {
    border: calc(1rem / 16) solid var(--color-grey);
    border-top: none;
    background-color: var(--color-blue);
    padding: calc(12rem / 16);
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(12rem / 16) calc(46rem / 16) calc(12rem / 16) calc(20rem / 16);
        font-size: calc(18rem / 16);
    }
}

.accordion__body a {
    color: var(--color-primary);
    text-decoration: underline;
}

.accordion-block__label {
    color: var(--color-text-muted);
    letter-spacing: calc(1rem/16);
    line-height: calc(19/16);
    margin-bottom: calc(8rem/16);
}
.accordion-block__text {
    letter-spacing: calc(1rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}