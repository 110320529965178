.title-block {
    color: var(--color-light);
    margin-bottom: calc(24rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(48rem/16);
    }
}
.title-block.title-block--bg-light {
    color: var(--color-dark);
}
.title-block__title{
    letter-spacing: calc(1rem/16);
}
.title-block__sub-title{
    font-size: calc(18rem/16);
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px){
       font-size: calc(24rem/16);
    }
}
.title-block.title-block--centered {
    text-align: center;
}
.title-block.title-block--bg-light{
    color: var(--color-blue);
}
.title-block__wysiwyg a:hover {
    color: #fff;
}