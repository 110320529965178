.status {
    background-color: var(--color-text-default);
    border-radius: 50%;
    color: var(--color-primary);
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.status--big {
    width: calc(30rem/16);
    height: calc(30rem/16);
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px) {
        width: calc(40rem/16);
        height: calc(40rem/16);
        font-size: calc(19rem/16);
    }
}
.status--check:before {
    content:var(--icon-check);
    font-family:'iconfont';
}