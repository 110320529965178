.portal-grid {
    display: grid;
    grid-template-columns: calc(75rem/16) 1fr;
    gap: 0 calc(20rem/16);
    grid-template-areas:
    "portal-grid__nav portal-grid__content";
    @media screen and (min-width: 768px) {
        grid-template-columns: calc(200rem/16) 1fr;
        gap: 0 calc(30rem/16);
    }
    @media screen and (min-width: 1200px) {
        grid-template-columns: calc(300rem/16) 1fr;
        gap: 0 calc(100rem/16);
    }
}
.portal-grid__nav { grid-area: portal-grid__nav; }
.portal-grid__content { grid-area: portal-grid__content; }

.portal-grid__title {
    font-size: clamp(calc(30rem/16), 6.5vw, calc(120rem/16));
    text-transform: uppercase;
    line-height: 1.4;
    font-family: var(--font-decorative);
}