.sticky-text-img-area {
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: calc(14rem/16) 0 ;
    grid-template-areas:
        "sticky-text-img-area__left"
        "sticky-text-img-area__right";
    @media screen and(min-width:768px){
        grid-template-columns: 37% 1fr;
        grid-template-rows: 1fr;
        gap:  0 calc(59rem/16);
        grid-template-areas: " sticky-text-img-area__left sticky-text-img-area__right";
    }
}
.sticky-text-img-area__right {
    grid-area: sticky-text-img-area__right;
}
.sticky-text-img-area__left {
    grid-area: sticky-text-img-area__left;
}
.sticky-text-img-area__img--landscape{
    max-width: calc(732rem/16);
    display: block;
}
.sticky-text-img-area__img--portrait{
    max-width: calc(468rem/16);
    display: block;
}
.sticky-text-img-area__date{
    font-size: calc(12rem/16);
    display: flex;
    align-items: center;
    line-height: 1;
    @media screen and (min-width:768px){
        font-size: clamp(calc(12rem/16), 1.25vw, calc(16rem/16));
    }
}
.sticky-text-img-area__icon {
    font-size: calc(18rem/16);
    margin-right: calc(6rem/16);
    @media screen and (min-width:768px){
        font-size: clamp(calc(18rem/16), 1.25vw, calc(25rem/16));
        margin-right: calc(12rem/16);
    }
}
.sticky-text-img-area__price {
    font-family: var(--font-decorative);
    @media screen and (min-width:768px){
        font-size: clamp(calc(24rem/16), 2vw, calc(36rem/16));
    }
}
.sticky-text-img-area__offer {
    font-size: calc(12rem/16);
    margin-top: calc(20rem/16);
    @media screen and (min-width:768px){
        font-size: clamp(calc(12rem/16), 1.25vw, calc(16rem/16));
        margin-top: clamp(calc(24rem/16), 2vw, calc(36rem/16));
    }
}
.sticky-text-img-area__date__dash {
    margin: 0 calc(3rem/16);
}
.sticky-text-img-area__offer__btn{
    margin-top: 1rem;
}
.sticky-text-img-area__services{
    margin-top: calc(20rem/16);
    @media screen and (min-width:768px){
        margin-top: clamp(calc(28rem/16), 2.5vw, calc(42rem/16));
    }
}