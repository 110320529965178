.hotel-booking-teaser:not(.hotel-booking-teaser--no-img) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: 0 0;
    grid-template-areas:
    "hotel-booking-teaser__image__wrapper"
    "hotel-booking-teaser__content";

    @media screen and(min-width: 768px) {
        grid-template-columns: 0.525fr 2fr;
        grid-template-rows: 1fr;
        gap: 0 0;
        grid-template-areas:
    "hotel-booking-teaser__image__wrapper hotel-booking-teaser__content";
    }
}

.hotel-booking-teaser:hover .hotel-booking-teaser__image {
    transform: scale(1.05);
}

.hotel-booking-teaser .hotel-booking-teaser__image {
    transition: transform 0.4s ease-in;
}

.hotel-booking-teaser__image__wrapper {
    grid-area: hotel-booking-teaser__image__wrapper;
    overflow: hidden;
}

.hotel-booking-teaser .ratio.ratio-cover-centered--md .ratio-item.vertical-teaser__image {
    transition: transform 0.2s ease-in, filter 0.2s ease-in;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hotel-booking-teaser:has(.collapse__header-link:not(.collapsed)) .vertical-teaser__image {
    filter: blur(10px);
}

.hotel-booking-teaser__content {
    background-color: #fff;
    grid-area: hotel-booking-teaser__content;
    padding: calc(12rem / 16);
    font-size: calc(12rem / 16);
    color: var(--color-blue);
    line-height: 1.5;
    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(30rem / 16);
        font-size: calc(16rem / 16);
        line-height: calc(22 / 18);
    }
}

.hotel-booking-teaser__detail-info {
    margin-top: calc(16rem / 16);
    @media screen and(min-width: 768px) {
        margin-top: calc(24rem / 16);
    }
}

.hotel-booking-teaser__detail-info__collapse {
    border: calc(1rem / 16) solid var(--color-blue);
    padding: calc(20rem / 16);
    background-color: var(--color-light);
}

.hotel-booking-teaser__rooms {
    margin-top: calc(1rem / 16);
    @media screen and(min-width: 768px) {
        margin-top: calc(14rem / 16);
    }
}

.hotel-booking-teaser__title {
    font-size: clamp(calc(11rem / 16), .875vw, calc(16rem / 16));
}

.hotel-booking-teaser__price {
    font-size: calc(25rem / 16);
    font-family: var(--font-decorative);
}

.hotel-booking-teaser__rooms-item {
    position: relative;
}

.hotel-booking-teaser__rooms-item:nth-child(even) + .hotel-booking-teaser__rooms-item:not(.border-0):before {
    @media screen and (min-width: 993px) {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: calc(1rem / 16);
        background-color: #B8B8B8;
    }
}