
.cse-search-form__input {
    height: calc(60rem/16);
    border: 1px solid var(--color-text-muted);
    display: block;
    width: 100%;
    color:#fff;
    font-size: calc(16rem/16);
    background-color: transparent;
    padding: calc(20rem/16) calc(35rem/16);
    border-radius: 0!important;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
@media screen and (min-width: 768px) {
    .cse-search-form__input:focus {
        outline: none;
        border-width: calc(2rem/16);
    }
}
.cse-search-form__input::placeholder {
    color:#fff;
}
.cse-search-form__btn {
    width: calc(60rem/16);
    height: calc(60rem/16);
    font-size: calc(24rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom:0;
    right:0;
    background-color: #fff;
    transition: transform 0.6s ease-in-out;
    @media screen and (max-width: 767px) {
        left: initial;
        right:0;
    }
}
.cse-search-form {
    margin: 0 auto;
    position: relative;
    @media screen and (max-width: 767px) {
        width: auto;
    }
}
.cse-teaser {
    background-color:#fff;
    color: var(--color-dark);
}
.cse-teaser__body {
    padding: calc(20rem/16);
    font-size: calc(14rem/16);
}
.cse-teaser__img-wrapper {
    height: 100%;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.cse-teaser__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}