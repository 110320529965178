.team-teaser {
    position: relative;
}

.team-teaser__img-wrapper:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    background: linear-gradient(180deg, rgba(3,12,23,0) 0%, rgba(3,12,23,0.6) 100%);
}
.team-teaser__img {
    filter: none;
    transition: filter ease-in-out 0.25s;
}

.team-teaser:hover .team-teaser__img {
    filter: grayscale(100%);
}

.team-teaser__body {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 calc(28rem/16) calc(30rem/16) calc(28rem/16);
}

.team-teaser__name {
    color: #fff;
    font-family: var(--font-decorative);
    font-size: calc(25rem/16);

    @media screen and (min-width: 1300px) {
        font-size: calc(35rem/16);

    }
}
.team-teaser__info {
    color: #fff;
    font-family: var(--font-default);
    font-size: calc(14rem/16);

    @media screen and (min-width: 1300px) {
        font-size: calc(22rem/16);
    }
}

.team-teaser__contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: calc(18rem/16);

    @media screen and (min-width: 1300px) {
        margin-top: calc(25rem/16);
    }
}

.team-teaser__tel {
    font-family: var(--font-default);
    font-size: calc(14rem/16);

    @media screen and (min-width: 1300px) {
        font-size: calc(18rem/16);
    }
}

.team-teaser__icon {
    color: var(--color-primary);
    padding-right: calc(5rem/16);

    @media screen and (min-width: 1300px) {
        padding-right: calc(10rem/16);
    }
}

/*--------------- button modifier? ------------*/
.team-teaser__email {
    @media screen and (min-width: 768px) and (max-width: 1300px) {
        padding: calc(11rem/16);
        font-size: calc(14rem/16);
    }
    @media screen and (max-width: 767px) {
        padding: calc(15rem/16);
        font-size: calc(14rem/16);
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .team-teaser__contact {
        flex-wrap: wrap;
    }

    .team-teaser__tel {
        margin-top: calc(5rem/16);
    }

}